(function(ng) {
    angular
        .module('fca.calculator')
        .service('calculatorService', calculatorService);

    function calculatorService($http, $q) {

        let $service = this;

        /*
         Return calculator data

         receive in entry the program object given by this endpoint :
         Example: api/buildandprice/program/modelYear/18235?provinceCode=ON

         output example :

         output = {
             financeInterestRate: {36: "5.99", 48: "5.99", 60: "5.99", 72: "5.99", 84: "4.99", 96: "4.99"}
             leaseInterestRate: {24: "5.0", 36: "5.0", 39: "6.09", 48: "6.0", 51: "6.09", 60: "6.09"}
             residualList: {
                 12K: {24: "63.0", 36: "55.0", 39: "54.0", 48: "48.0", 51: "47.0", 60: "40.0"}
                 16K: {24: "63.0", 36: "55.0", 39: "54.0", 48: "48.0", 51: "47.0", 60: "40.0"}
                 20K: {24: "61.0", 36: "70.0", 39: "52.0", 48: "46.0", 51: "80.0", 60: "38.0"}
                 24K: {24: "60.0", 36: "52.0", 39: "51.0", 48: "45.0", 51: "44.0", 60: "37.0"}
             }
         }
         */
        $service.buildCalculatorData = (programObj, optionList, defaultFinanceRate) => {
            let result = {};
            let defaultAcceptedFinanceRateList = {36: defaultFinanceRate, 48: defaultFinanceRate, 60: defaultFinanceRate,
                72: defaultFinanceRate, 84: defaultFinanceRate, 96: defaultFinanceRate};
            //finance data
            if(programObj && programObj.finance) {
                let acceptedFinanceTermList = getAcceptedInterestRate(programObj.finance, optionList);
                // set default accepted finance interestRate
                acceptedFinanceTermList.push(defaultAcceptedFinanceRateList);
                if (Object.keys(acceptedFinanceTermList).length > 0) {
                    let bestFinanceInterestRate = getBestInterestRate(acceptedFinanceTermList);
                    result['financeInterestRate'] = bestFinanceInterestRate;
                }
            } else {
                result['financeInterestRate'] = defaultAcceptedFinanceRateList;
            }

            //lease data
            if(programObj && programObj.lease) {
                let acceptedLeaseTermList = getAcceptedInterestRate(programObj.lease, optionList);
                if (Object.keys(acceptedLeaseTermList).length > 0) {
                    let bestLeaseInterestRate = getBestInterestRate(acceptedLeaseTermList);
                    result['leaseInterestRate'] = bestLeaseInterestRate;
                }

                //residual data
                let leaseResiduals = getBestAcceptedResidual(programObj.residual, optionList);
                result['residualList'] = leaseResiduals;

                //if no residual is accepted, we do not show lease
                if (!result['residualList']) {
                    delete result['leaseInterestRate'];
                }
            }

            return result;
        };

        $service.getSalesTax = () => {
            var deferredObject = $q.defer();

            $http({
                method : "GET",
                url : "/data/config/salestax"
            }).then(response => {
                deferredObject.resolve(response);
            }, response => {
                deferredObject.reject(response);
            });

            return deferredObject.promise;
        };

        /*
         Calculate lease payment
         Following SCI lease algorithm

         Entry object example :

         let leaseData = {
             "amount": 30490,
             "interestRate": 3.99,
             "term": 60,
             "residual": 38,
             "downpayment": 4000,
             "tradeInValue": 2000,
             "oweOnTrade": 1000,
             "nbOfPaymentPerYear": 12,
             "discountAfterTax": 0,
             "discountBeforeTax": 0
         };

         return payment

         */

        $service.getElectricDiscounts = () => {
            var deferredObject = $q.defer();

            $http({
                method : "GET",
                url : "/data/config/electric-discounts"
            }).then(response => {
                deferredObject.resolve(response);
            }, response => {
                deferredObject.reject(response);
            });

            return deferredObject.promise;
        };

        $service.calculateLeasePayment = (leaseData, roundUp = true) => {

            // validate values
            let validLeaseData = validateValues(leaseData);

            // Total value of the car
            let debug = leaseData.debug || '';
            let gkrp = validLeaseData['gkrp'];
            let amount = validLeaseData['amount'];
            // Interest rate percentage (5.49)
            let interestRate = validLeaseData['interestRate'];
            // Number of months (36, 48, 60)
            let term = validLeaseData['term'];
            // Residual percentage value at end of lease term (32)
            let residual = validLeaseData['residual'];
            let downpayment = validLeaseData['downpayment'];
            let tradeInValue = validLeaseData['tradeInValue'];
            // Amount still owed on trade in value (we'll simply subtract from trade in value)
            let oweOnTrade = validLeaseData['oweOnTrade'];
            let nbOfPaymentPerYear = validLeaseData['nbOfPaymentPerYear'];
            let discountAfterTax = validLeaseData['discountAfterTax'];
            let discountBeforeTax = validLeaseData['discountBeforeTax'];

            // we sometimes don't have the GKRP, when that happens we use the lease amount (although it's not accurate)
            if (gkrp == 0) {
                gkrp = amount;
            }

            let result = {};

            // remove owe on trade from trade in value
            let netTradeInValue = tradeInValue - oweOnTrade;

            // Calculate net cost by subtracting downpayment, trade in value, owe on trade and non residualized amount from lease amount.
            let netCost = amount - downpayment - netTradeInValue - discountBeforeTax - discountAfterTax;

            let calculatedPayment = calculateLeasePayment(netCost, gkrp, term, interestRate, nbOfPaymentPerYear, residual);
            let payment = calculatedPayment ? calculatedPayment.payment : 0;
            result['residual'] = calculatedPayment ? round(calculatedPayment.residual, 2) : 0;

            // we never return a negative payment
            if (payment < 0) {
                payment = 0;
                // warn the user that we calculated an invalid payment
                result['invalidPayment'] = true;
            } else if(roundUp) {
                payment = Math.ceil(payment);
            }

            result['payment'] = payment;

            // Due on delivery calculation
            let totalDueOnDelivery = downpayment;

            let dueOnDeliveryComponents = {'downpayment':downpayment, 'totalDueOnDelivery': totalDueOnDelivery};

            result['dueOnDelivery'] = dueOnDeliveryComponents;

            console.debug('%cLease ' + debug, 'color:LawnGreen; background:black',
                    {
                        amount : amount,
                        gkrp : gkrp,
                        interestRate : interestRate,
                        term : term,
                        residual : residual,
                        downpayment : downpayment,
                        tradeInValue : tradeInValue,
                        oweOnTrade : oweOnTrade,
                        nbOfPaymentPerYear : nbOfPaymentPerYear,
                        discountBeforeTax : discountBeforeTax,
                        discountAfterTax : discountAfterTax,
                        netCost : netCost,
                        totalDueOnDelivery : totalDueOnDelivery,
                        payment : payment
                    }
                );
            return result;
        };

        $service.calculateLeasePaymentWithTax = (leaseData, salesTax, provinceCode, language) => {
            // validate values
            let validLeaseData = validateValues(leaseData);

            if (!salesTax) {
                throw Error("sales tax object is null");
            }

            // see $service.calculateLeasePayment for comments on values
            let debug = leaseData.debug || '';
            let amount = validLeaseData['amount'];
            let gkrp = validLeaseData['gkrp'];
            let interestRate = validLeaseData['interestRate'];
            let term = validLeaseData['term'];
            let residual = validLeaseData['residual'];
            let downpayment = validLeaseData['downpayment'];
            let tradeInValue = validLeaseData['tradeInValue'];
            let oweOnTrade = validLeaseData['oweOnTrade'];
            let nbOfPaymentPerYear = validLeaseData['nbOfPaymentPerYear'];
            let discountAfterTax = validLeaseData['discountAfterTax'];
            let discountBeforeTax = validLeaseData['discountBeforeTax'];

            // we sometimes don't have the GKRP, when that happens we use the lease amount (although it's not accurate)
            if (gkrp == 0) {
                gkrp = amount;
            }

            let result = {};
            let dueOnDelivery = {};

            let gstHst = 0;
            let pst = 0;

            salesTax = $service.filterSaleTaxesBrackets(salesTax, amount - discountBeforeTax);

            // set taxes
            for (var i = 0; i < salesTax.taxes.length; i++) {
                if (salesTax.taxes[i].taxType === 'gstHst') {
                    gstHst = parseFloat(salesTax.taxes[i].tax);
                    result['gstHstTaxLabel'] = salesTax.taxes[i].labelEn;
                    if(language == 'fr') {
                        result['gstHstTaxLabel'] = salesTax.taxes[i].labelFr;
                    }
                }
                if (salesTax.taxes[i].taxType === 'pst') {
                    pst = parseFloat(salesTax.taxes[i].tax);
                    result['pstTaxLabel'] = salesTax.taxes[i].labelEn;
                    if(language == 'fr') {
                        result['pstTaxLabel'] = salesTax.taxes[i].labelFr;
                    }
                }
            }

            tradeInValue = tradeInValue - oweOnTrade;

            let totalCost = amount;
            let totalReduction = downpayment + tradeInValue + discountBeforeTax;
            let amortizeAmount = totalCost - totalReduction;

            console.debug('%cLease with tax - Parameters', 'color:LawnGreen; background:black',
                {
                    gst : gstHst,
                    pst : pst,
                    downpayment : downpayment,
                    tradeInValue : tradeInValue,
                    discountBeforeTax : discountBeforeTax,
                    discountAfterTax : discountAfterTax,
                    totalCost : totalCost,
                    totalReduction : "downpayment + tradeInValue + discountBeforeTax + discountAfterTax = " + totalReduction,
                    amortizeAmount : "totalCost - totalReduction = " +amortizeAmount,
                });

            // GST/HST Taxable Amount
            const gstHstTaxableAmount = amortizeAmount;

            let gstHstTaxAmount = gstHstTaxableAmount * (gstHst/100);
            let roundedGstHstTaxAmount = Math.ceil(gstHstTaxAmount);
            if(roundedGstHstTaxAmount > 0) {
                result['gstHstTaxAmount'] = roundedGstHstTaxAmount;
            }

            // PST Taxable Amount
            let pstTaxableAmount;
            if (provinceCode == 'BC' || provinceCode == 'SK') {
                if((amortizeAmount + tradeInValue) > 0) {
                    pstTaxableAmount = amortizeAmount + tradeInValue;
                } else {
                    pstTaxableAmount = 0;
                }
            } else {
                pstTaxableAmount = amortizeAmount;
            }

            let pstTaxAmount = pstTaxableAmount * (pst/100);

            pstTaxAmount = Math.ceil(pstTaxAmount);

            if(pstTaxAmount > 0) {
                result['pstTaxAmount'] = pstTaxAmount;
            }

            let salesTaxAmount = gstHstTaxAmount + pstTaxAmount;

            result['salesTaxAmount'] = salesTaxAmount;

            let taxReduction = 0;

            result['taxReduction'] = taxReduction;

            const leasePayment = calculateLeasePayment(amortizeAmount - discountAfterTax, gkrp, term, interestRate, nbOfPaymentPerYear, residual).payment;
            let gstHstTaxablePayment = calculateLeasePayment(gstHstTaxableAmount, gkrp, term, interestRate, nbOfPaymentPerYear, residual).payment;
            let gstHstTaxAmountPerPayment = gstHstTaxablePayment * (gstHst / 100);
            let pstTaxablePayment = calculateLeasePayment(pstTaxableAmount, gkrp, term, interestRate, nbOfPaymentPerYear, residual).payment;
            let pstTaxAmountPerPayment = pstTaxablePayment * (pst / 100);
            let paymentWithTax = leasePayment + gstHstTaxAmountPerPayment + pstTaxAmountPerPayment;

            console.debug('%cLease with tax - calculations', 'color:LawnGreen; background:black',
                {
                    leasePayment : leasePayment,
                    gstHstTaxablePayment : gstHstTaxablePayment,
                    gstHstTaxAmountPerPayment : " gstHstTaxablePayment * (gstHst / 100) = " + gstHstTaxAmountPerPayment,
                    pstTaxablePayment : "pstTaxablePayment * (pst / 100)" + pstTaxablePayment,
                    pstTaxAmountPerPayment : + pstTaxAmountPerPayment,

                });

            paymentWithTax = round(paymentWithTax, 2);

            // we never return a negative payment
            if (paymentWithTax < 0) {
                paymentWithTax = 0;
                // warn the user that we calculated an invalid payment
                result['invalidPayment'] = true;
            } else {
                paymentWithTax = Math.ceil(paymentWithTax);
            }

            result['payment'] = paymentWithTax;

            // Tax on downpayment , discount after tax and tax on trade in value(only in BC and SK) are are calculated here
            // Due on delivery calculation
            let downpaymentWithTax = downpayment * (((pst + gstHst) / 100) + 1);
            let taxOnDiscountAfterTax = discountAfterTax * ((pst + gstHst) / 100);
            let taxOnTradeInValue;
            if (provinceCode == 'BC' || provinceCode == 'SK') {
                taxOnTradeInValue = tradeInValue * ((pst + gstHst) / 100);
            } else {
                taxOnTradeInValue = 0;
            }

            let totalDueOnDelivery = downpaymentWithTax + taxOnDiscountAfterTax + taxOnTradeInValue;
            let dueOnDeliveryComponents = {
                'downpaymentWithTax': downpaymentWithTax,
                'taxOnDiscountAfterTax': taxOnDiscountAfterTax,
                'taxOnTradeInValue': taxOnTradeInValue,
                'totalDueOnDelivery': totalDueOnDelivery
            };

            result['dueOnDelivery'] = dueOnDeliveryComponents;

            console.debug('%cLease with tax ' + debug, 'color:LawnGreen; background:black',
                {
                    amount : amount,
                    gkrp : gkrp,
                    interestRate : interestRate,
                    term : term,
                    residual : residual,
                    downpayment : downpayment,
                    tradeInValue : tradeInValue,
                    oweOnTrade : oweOnTrade,
                    nbOfPaymentPerYear : nbOfPaymentPerYear,
                    discountBeforeTax : discountBeforeTax,
                    discountAfterTax : discountAfterTax,
                    provinceCode : provinceCode,
                    gstHst : gstHst,
                    gstHstTaxAmount : gstHstTaxAmount,
                    pst : pst,
                    pstTaxAmount : pstTaxAmount,
                    downpaymentWithTax : downpaymentWithTax,
                    taxOnDiscountAfterTax : taxOnDiscountAfterTax,
                    taxOnTradeInValue : taxOnTradeInValue,
                    totalDueOnDelivery : totalDueOnDelivery,
                    leasePayment : leasePayment,
                    gstHstTaxAmountPerPayment : gstHstTaxAmountPerPayment,
                    pstTaxAmountPerPayment : pstTaxAmountPerPayment,
                    paymentWithTax : paymentWithTax,
                    taxReduction : taxReduction
                });

            return result;
        };

        /*
         Calculate Finance payment

         Entry object example :

         let financeData = {
             "amount": 30490,
             "interestRate": 3.99,
             "term": 60,
             "downpayment": 4000,
             "tradeInValue": 2000,
             "oweOnTrade": 1000,
             "nbOfPaymentPerYear": 12,
             "discountAfterTax": 1500,
             "discountBeforeTax": 3750
         };

         */
        // TODO due on delivery needed here?
        $service.calculateFinancePayment = (financeData, roundUp = true) => {
            // validate values
            let validFinanceData = validateValues(financeData);

            // see $service.calculateLeasePayment for comments on values
            let debug = financeData.debug || '';
            let amount = validFinanceData['amount'];
            let interestRate = validFinanceData['interestRate'];
            let term = validFinanceData['term'];
            let downpayment = validFinanceData['downpayment'];
            let tradeInValue = validFinanceData['tradeInValue'];
            let oweOnTrade = validFinanceData['oweOnTrade'];
            let nbOfPaymentPerYear = validFinanceData['nbOfPaymentPerYear'];
            let discountAfterTax = validFinanceData['discountAfterTax'];
            let discountBeforeTax = validFinanceData['discountBeforeTax'];

            let result = {};

            // Calculate net cost by substracting downpayment, trade in value, owe on trade and non residualized amount from finance amount.
            let netCost = amount - (downpayment + (tradeInValue - oweOnTrade)) - discountAfterTax - discountBeforeTax;
            let payment = 0;
            // do not calculate finance on a zero dollar net cost
            if (netCost > 0) {
                payment = calculateFinancePayment(netCost, term, interestRate, nbOfPaymentPerYear);
            } else {
                result['netzero'] = true;
            }

            // we never return a negative payment
            if (payment < 0) {
                payment = 0;
                // warn the user that we calculated an invalid payment
                result['invalidPayment'] = true;
            } else if(roundUp) {
                payment = Math.ceil(payment);
            }

            result['payment'] = payment;

            console.debug('%cFinance ' + debug, 'color:LawnGreen; background:black',
                    {
                        amount : amount,
                        interestRate : interestRate,
                        term : term,
                        downpayment : downpayment,
                        tradeInValue : tradeInValue,
                        oweOnTrade : oweOnTrade,
                        nbOfPaymentPerYear : nbOfPaymentPerYear,
                        discountBeforeTax : discountBeforeTax,
                        discountAfterTax : discountAfterTax,
                        netCost : netCost,
                        payment : payment
                    }
            );

            return result;
        };

        /**
         * Only meant to calculate taxes on an amount
         */
        $service.calculateCashTaxes = (cashData, salesTax, provinceCode, language) => {
            // validate values
            let validCashData = validateValues(cashData);

            if (!salesTax) {
                throw Error("sales tax object is null");
            }

            let amount = validCashData['amount'];
            let downpayment = validCashData['downpayment'];
            let tradeInValue = validCashData['tradeInValue'];
            let oweOnTrade = validCashData['oweOnTrade'];
            let discountAfterTax = validCashData['discountAfterTax'];
            let discountBeforeTax = validCashData['discountBeforeTax'];

            let result = {};

            let gstHst = 0;
            let pst = 0;

            salesTax = $service.filterSaleTaxesBrackets(salesTax, amount - discountBeforeTax);

            // set taxes
            for (var i = 0; i < salesTax.taxes.length; i++) {
                if (salesTax.taxes[i].taxType === 'gstHst') {
                    gstHst = parseFloat(salesTax.taxes[i].tax);
                    result['gstHstTaxLabel'] = salesTax.taxes[i].labelEn;
                    if(language == 'fr') {
                        result['gstHstTaxLabel'] = salesTax.taxes[i].labelFr;
                    }
                }
                if (salesTax.taxes[i].taxType === 'pst') {
                    pst = parseFloat(salesTax.taxes[i].tax);
                    result['pstTaxLabel'] = salesTax.taxes[i].labelEn;
                    if(language == 'fr') {
                        result['pstTaxLabel'] = salesTax.taxes[i].labelFr;
                    }
                }
            }

            // Calculate net cost by substracting downpayment, trade in value, owe on trade and non residualized amount from finance amount.
            let preTaxNetCost = amount - (downpayment + (tradeInValue - oweOnTrade)) - discountBeforeTax - discountAfterTax;

            // add afterTax discount to preTaxNetCost to get amount on which we apply tax
            let applicableTaxAmount = preTaxNetCost + downpayment + discountAfterTax;

            // calculate applicable tax amount
            let gstHstTaxAmount = (applicableTaxAmount * (gstHst / 100));

            // round the amount
            gstHstTaxAmount = Math.ceil(gstHstTaxAmount);

            if(gstHstTaxAmount > 0) {
                result['gstHstTaxAmount'] = gstHstTaxAmount;
            }

            let pstTaxAmount = (applicableTaxAmount * (pst / 100));

            // round the amount
            pstTaxAmount = Math.ceil(pstTaxAmount);

            if(pstTaxAmount > 0) {
                result['pstTaxAmount'] = pstTaxAmount;
            }

            let salesTaxAmount = gstHstTaxAmount + pstTaxAmount;

            result['salesTaxAmount'] = salesTaxAmount;

            let taxReduction = 0;

            result['taxReduction'] = taxReduction;

            console.debug('%cTaxes', 'color:LawnGreen; background:black',
                {
                    amount : amount,
                    downpayment : downpayment,
                    tradeInValue : tradeInValue,
                    oweOnTrade : oweOnTrade,
                    discountBeforeTax : discountBeforeTax,
                    discountAfterTax : discountAfterTax,
                    applicableTaxAmount : applicableTaxAmount,
                    gstHst : gstHst,
                    gstHstTaxAmount : gstHstTaxAmount,
                    pst : pst,
                    pstTaxAmount : pstTaxAmount,
                    salesTaxAmount : salesTaxAmount,
                    taxReduction : taxReduction
                }
            );

            return result;
        };

        /*
         Calculate Finance payment with tax

         Entry object example :

         let financeData = {
             "amount": 30490,
             "interestRate": 3.99,
             "term": 60,
             "downpayment": 4000,
             "tradeInValue": 2000,
             "oweOnTrade": 1000,
             "nbOfPaymentPerYear": 12,
             "discountAfterTax": 1500,
             "discountBeforeTax": 3750
         };

         return example :
             {
                 gstHstTaxAmount: 1534.5
                 gstHstTaxLabel: "gstHstTaxLabel"
                 payment: 70.14
                 pstTaxAmount: 2455.2
                 pstTaxLabel: "gstHstTaxLabel"
                 salesTaxAmount: 3989.7
             }
         */
        $service.calculateFinancePaymentWithTax = (financeData, salesTax, provinceCode, language) => {
            // validate values
            let validFinanceData = validateValues(financeData);

            // see $service.calculateLeasePayment for comments on values
            let debug = financeData.debug || '';
            let amount = validFinanceData['amount'];
            let interestRate = validFinanceData['interestRate'];
            let term = validFinanceData['term'];
            let downpayment = validFinanceData['downpayment'];
            let tradeInValue = validFinanceData['tradeInValue'];
            let oweOnTrade = validFinanceData['oweOnTrade'];
            let nbOfPaymentPerYear = validFinanceData['nbOfPaymentPerYear'];
            let discountAfterTax = validFinanceData['discountAfterTax'];
            let discountBeforeTax = validFinanceData['discountBeforeTax'];

            let result = {};

            let gstHst = 0;
            let pst = 0;

            salesTax = $service.filterSaleTaxesBrackets(salesTax, amount - discountBeforeTax);

            // set taxes
            for (var i = 0; i < salesTax.taxes.length; i++) {
                if (salesTax.taxes[i].taxType === 'gstHst') {
                    gstHst = parseFloat(salesTax.taxes[i].tax);
                    result['gstHstTaxLabel'] = salesTax.taxes[i].labelEn;
                    if(language == 'fr') {
                        result['gstHstTaxLabel'] = salesTax.taxes[i].labelFr;
                    }
                }
                if (salesTax.taxes[i].taxType === 'pst') {
                    pst = parseFloat(salesTax.taxes[i].tax);
                    result['pstTaxLabel'] = salesTax.taxes[i].labelEn;
                    if(language == 'fr') {
                        result['pstTaxLabel'] = salesTax.taxes[i].labelFr;
                    }
                }
            }

            // Calculate net cost by substracting downpayment, trade in value, owe on trade and non residualized amount from finance amount.
            let preTaxNetCost = amount - (downpayment + (tradeInValue - oweOnTrade)) - discountBeforeTax - discountAfterTax;

            // add afterTax discount to preTaxNetCost to get amount on which we apply tax
            let applicableTaxAmount = preTaxNetCost + downpayment + discountAfterTax;

            // calculate applicable tax amount
            let gstHstTaxAmount = (applicableTaxAmount * (gstHst / 100));
            gstHstTaxAmount = Math.ceil(gstHstTaxAmount);

            if(gstHstTaxAmount > 0) {
                result['gstHstTaxAmount'] = gstHstTaxAmount;
            }

            let pstTaxAmount = (applicableTaxAmount * (pst / 100));

            // round the pst taxes amount
            pstTaxAmount = Math.ceil(pstTaxAmount);

            if(pstTaxAmount > 0) {
                result['pstTaxAmount'] = pstTaxAmount;
            }

            let salesTaxAmount = gstHstTaxAmount + pstTaxAmount;

            result['salesTaxAmount'] = salesTaxAmount;

            let taxReduction = 0;

            result['taxReduction'] = taxReduction;

            let applicableTaxAmountWithTax = applicableTaxAmount + gstHstTaxAmount + pstTaxAmount;

            // Remove downpayment and discount after tax
            let netCostWithTax = applicableTaxAmountWithTax - downpayment - discountAfterTax;

            let payment = 0;

            // Payment calculation
            if (netCostWithTax > 0) {
                payment = calculateFinancePayment(netCostWithTax, term, interestRate, nbOfPaymentPerYear);
            } else {
                result['netzero'] = true;
            }

            // we never return a negative payment
            if (payment < 0) {
                payment = 0;
                // warn the user that we calculated an invalid payment
                result['invalidPayment'] = true;
            } else {
                payment = Math.ceil(payment);
            }

            result['payment'] = payment;

            console.debug('%cFinance with tax ' + debug, 'color:LawnGreen; background:black',
                {
                    amount : amount,
                    interestRate : interestRate,
                    term : term,
                    downpayment : downpayment,
                    tradeInValue : tradeInValue,
                    oweOnTrade : oweOnTrade,
                    nbOfPaymentPerYear : nbOfPaymentPerYear,
                    discountBeforeTax : discountBeforeTax,
                    discountAfterTax : discountAfterTax,
                    provinceCode : provinceCode,
                    applicableTaxAmount : applicableTaxAmount,
                    gstHst : gstHst,
                    gstHstTaxAmount : gstHstTaxAmount,
                    pst : pst,
                    pstTaxAmount : pstTaxAmount,
                    netCostWithTax : netCostWithTax,
                    payment : payment,
                    taxReduction : taxReduction
                }
            );

            return result;
        };

        /*
          @param saleTax: salesTax object
          @param amount: value of the vehicle before reduction
          @returns {*}
         */
        $service.filterSaleTaxesBrackets = (saleTax, amount) => {
            if (saleTax && saleTax.hasTaxBracket) {
                let bracketsSaleTaxesObject = saleTax;
                if (bracketsSaleTaxesObject.taxBrackets !== undefined) {
                    for (let y = 0; y < bracketsSaleTaxesObject.taxBrackets.length; y++) {
                        let lesserThan = Number.MAX_VALUE;
                        let higherThan = Number.MIN_VALUE;
                        if (bracketsSaleTaxesObject.taxBrackets[y].lesserThan !== null) {
                            lesserThan = bracketsSaleTaxesObject.taxBrackets[y].lesserThan;
                        }
                        if (bracketsSaleTaxesObject.taxBrackets[y].higherThan !== null) {
                            higherThan = bracketsSaleTaxesObject.taxBrackets[y].higherThan;
                        }
                        if (lesserThan >= amount && higherThan <= amount) {
                            saleTax.taxes = bracketsSaleTaxesObject.taxBrackets[y].taxes;
                        }
                    }
                }
            }
            return saleTax;
        };

        /*
            get number of payment per year by frequency code
         */
        $service.getNbPaymentPerYear= (frequencyCode) => {
            // default to weekly
            let nbPaymentPerYear= 52;
            switch(frequencyCode) {
                case "monthly":
                case "0": {
                    nbPaymentPerYear = 12;
                    break;
                }
                case "bimonthly":
                case "1": {
                    nbPaymentPerYear = 24;
                    break;
                }
                case "weekly":
                case "2": {
                    nbPaymentPerYear = 52;
                    break;
                }
                case "biweekly":
                case "3": {
                    nbPaymentPerYear = 26;
                    break;
                }
            }
            return nbPaymentPerYear;
        };

        function calculateLeasePayment(netCost, gkrp, term, interestRate, nbOfPaymentPerYear, residual) {
            // calculate residual value
            let residualValue = 0;
            if(residual > 0) {
                residualValue = gkrp * (residual / 100);
            }

            let interestRateFactor = (interestRate / 100) / nbOfPaymentPerYear;
            let totalNumberOfPayment = Math.ceil((term / 12) * nbOfPaymentPerYear);

            let payment;

            if (interestRate === 0) {
                payment = (netCost - residualValue) / totalNumberOfPayment;
            } else {
                let netCostLessResidual = 0;
                if(residualValue > 0) {
                    netCostLessResidual = (netCost - (residualValue / Math.pow(1 + interestRateFactor, totalNumberOfPayment)));
                } else {
                    netCostLessResidual = netCost;
                }

                let part2 = ((1 - (1 / Math.pow(1 + interestRateFactor, totalNumberOfPayment - 1))) / interestRateFactor) + 1;
                payment = netCostLessResidual / part2;
            }

            payment = round(payment, 2);

            // we never return infinite number
            if (payment == Number.POSITIVE_INFINITY ) {
                throw Error("infinite payment was calculated(%s)", payment);
            }

            return { payment: payment, residual: residualValue };
        }

        function calculateFinancePayment(netCost, term, interestRate, nbOfPaymentPerYear) {
            let totalNumberOfPayment = Math.ceil((term / 12) * nbOfPaymentPerYear);

            let payment;

            if (interestRate === 0) {
                payment = netCost / totalNumberOfPayment;
            } else {
                // interest rate per month
                let interestRateFactor = (interestRate / 100) / nbOfPaymentPerYear;
                let part1 = (interestRateFactor * (Math.pow(1 + interestRateFactor, totalNumberOfPayment)));
                let part2 = (Math.pow((1 + interestRateFactor), totalNumberOfPayment) - 1);
                payment = netCost * (part1 / part2);
            }

            payment = round(payment, 2);

            // we never return infinite number
            if (payment == Number.POSITIVE_INFINITY ) {
                throw Error("infinite payment was calculated(%s)", payment);
            }

            return payment;
        }

        /*
         * validate that values are not null or negative.
         */
        function validateValues(calculateData) {

            let validData = {};

            // assign values for calculation
            validData['amount'] = Number(calculateData['amount']) || 0;
            validData['interestRate'] = Number(calculateData['interestRate']) || 0;
            validData['term'] = Number(calculateData['term']) || 0;
            validData['residual'] = Number(calculateData['residual']) || 0;
            validData['downpayment'] = Number(calculateData['downpayment']) || 0;
            validData['tradeInValue'] = Number(calculateData['tradeInValue']) || 0;
            validData['oweOnTrade'] = Number(calculateData['oweOnTrade']) || 0;
            validData['nbOfPaymentPerYear'] = Number(calculateData['nbOfPaymentPerYear']) || 0;
            validData['discountAfterTax'] = Number(calculateData['discountAfterTax']) || 0;
            validData['discountBeforeTax'] = Number(calculateData['discountBeforeTax']) || 0;
            validData['gkrp'] = Number(calculateData['gkrp']) || 0;

            if (validData['amount'] < 0) {
                throw Error("amount(" + validData['amount'] + ") is negative");
            }
            if (validData['interestRate'] < 0) {
                throw Error("interestRate(" + validData['interestRate'] + ") is negative");
            }
            if (validData['term'] < 0) {
                throw Error("term(" + validData['term'] + ") is negative");
            }
            if (validData['residual'] < 0) {
                throw Error("residual(" + validData['residual'] + ") is negative");
            }
            if (validData['downpayment'] < 0) {
                throw Error("downpayment(" + validData['downpayment'] + ") is negative");
            }
            if (validData['tradeInValue'] < 0) {
                throw Error("tradeInValue(" + validData['tradeInValue'] + ") is negative");
            }
            if (validData['oweOnTrade'] < 0) {
                throw Error("oweOnTrade(" + validData['oweOnTrade'] + ") is negative");
            }
            if (validData['nbOfPaymentPerYear'] < 0) {
                throw Error("nbOfPaymentPerYear(" + validData['nbOfPaymentPerYear'] + ") is negative");
            }
            if (validData['discountAfterTax'] < 0) {
                throw Error("discountAfterTax(" + validData['discountAfterTax'] + ") is negative");
            }
            // discount before tax is intentionally not validated, because it can be negative (for admin fees)
            if (validData['gkrp'] < 0) {
                throw Error("gkrp(" + validData['gkrp'] + ") is negative");
            }
            return validData;
        }

        /*
         * Return accepted interest rate
         */
        function getAcceptedInterestRate(interestRateList, optionList) {
            let acceptedTermList = [];
            for (let i = 0; i < interestRateList.length; i++) {
                let termList = interestRateList[i];
                // if option list is null, we accept it
                let isAccepted = true;
                if (termList.optionlogic) {
                    isAccepted = evaluateOptionLogic(termList.optionlogic, optionList);
                }
                if (isAccepted) {
                    acceptedTermList.push(termList.term);
                }
            }
            return acceptedTermList;
        }

        /*
         * Find accepted residual list and get the best of them for each available mileage
         */
        function getBestAcceptedResidual(residualObj, optionList) {
            let acceptedResidualList = [];
            let acceptedResidualObject = {};

            for (let i = 0; i < residualObj.length; i++) {
                let termList = residualObj[i];
                // if option list is null, we accept it
                let isAccepted = true;
                if (termList.optionlogic) {
                    isAccepted = evaluateOptionLogic(termList.optionlogic, optionList);
                }
                if (isAccepted) {
                    // if no mileage is specified we do not seperate them by mileage
                    if (termList.mileage) {
                        if (acceptedResidualObject[termList.mileage]) {
                            acceptedResidualObject[termList.mileage].push(termList.term);
                        } else {
                            acceptedResidualObject[termList.mileage] = [];
                            acceptedResidualObject[termList.mileage].push(termList.term);
                        }
                    } else {
                        acceptedResidualList.push(termList.term);
                    }
                }
            }

            let bestAcceptedResidual = {};
            if (Object.keys(acceptedResidualList).length > 0) {
                // in this case we only have a list with no mileage specified, we are getting 24k mileage residual(high mileage)
                let best24KResidualList = getBestResidual(acceptedResidualList);
                // create best 18k residual list from 24k residual list by adding 2 to every residual percentage
                let best24KResidualkeys = Object.keys(best24KResidualList);
                let best18KResidualList = {};
                for (let i = 0; i < best24KResidualkeys.length; i++) {
                    best18KResidualList[best24KResidualkeys[i]] = (Number(best24KResidualList[best24KResidualkeys[i]]) + 2).toString();
                }
                bestAcceptedResidual['18K'] = best18KResidualList;
                bestAcceptedResidual['24K'] = best24KResidualList;
            }

            // For now it's only in alfa romeo cases that we have four specific mileage
            if (Object.keys(acceptedResidualObject).length > 0) {
                let mileageList = Object.keys(acceptedResidualObject);
                for (let i = 0; i < mileageList.length; i++) {
                    bestAcceptedResidual[mileageList[i]] = getBestResidual(acceptedResidualObject[mileageList[i]]);
                }
            }
            return bestAcceptedResidual;
        }


        /*
         * Return the best interest rate list
         */
        function getBestInterestRate(interestRateMap) {
            let optimizedValues = {};
            for (let i = 0; i < interestRateMap.length; i++) {
                for (let x = 0; x < Object.keys(interestRateMap[i]).length; x++) {
                    let key = Object.keys(interestRateMap[i])[x];
                    let value = interestRateMap[i][key];
                    // if contains key
                    if (optimizedValues[key]) {
                        // if new value is better key, replace
                        if (optimizedValues[key] > value) {
                            optimizedValues[key] = value;
                        }
                    } else {
                        // if empty, add value
                        optimizedValues[key] = value;
                    }
                }
            }
            return optimizedValues;
        }

        /*
         * Create the best residual list
         */
        function getBestResidual(residualMap) {
            let optimizedValues = {};
            for (let i = 0; i < residualMap.length; i++) {
                for (let x = 0; x < Object.keys(residualMap[i]).length; x++) {
                    let key = Object.keys(residualMap[i])[x];
                    let value = residualMap[i][key];
                    // if contains key
                    if (optimizedValues[key]) {
                        // if new value is better replace
                        if (optimizedValues[key] < value) {
                            optimizedValues[key] = value;
                        }
                    } else {
                        // if empty, add value
                        optimizedValues[key] = value;
                    }
                }
            }
            return optimizedValues;
        }

        /*
         * Evaluate option logic with optionList
         * optionList contains package and options of a given vehicle
         */
        function evaluateOptionLogic(optionLogic, optionList) {
            let result = false;
            if (optionLogic) {
                try {
                    // remove dash in optionLogic. All option with dash are accepted if the chars before the dash match.
                    let stripedOptionLogic = optionLogic.replace(/-\*/g, "");

                    let stripedOption = [];
                    // remove dash/underline and char after it from options
                    for (let i = 0; i < optionList.length; i++) {
                        let option = optionList[i];
                        if (option.includes("-")) {
                            stripedOption.push(option.substring(0, option.indexOf('-')));
                        } else {
                            stripedOption.push(option);
                        }
                        if (option.includes("_")) {
                            stripedOption.push(option.substring(0, option.indexOf('_')));
                        } else {
                            stripedOption.push(option);
                        }
                    }

                    // match option in optionLogic statement with regex
                    let regex = /([^\[\]()|&!<>]+)/g;
                    let match = regex.exec(stripedOptionLogic);
                    let logicStatment = stripedOptionLogic;
                    while (match != null) {
                        let matchOption = match[0];
                        // is the match present in list option
                        let included = stripedOption.includes(matchOption);

                        // if option is include in optionLogic, replace it by a true statement, if not replace it by a false.
                        if (included) {
                            logicStatment = logicStatment.replace(matchOption, 'true');
                        } else {
                            logicStatment = logicStatment.replace(matchOption, 'false');
                        }

                        match = regex.exec(stripedOptionLogic);
                    }

                    //  brackets and square brackets becomes parenthesis for evaluation
                    logicStatment = logicStatment.replace(/\</g, "(");
                    logicStatment = logicStatment.replace(/\>/g, ")");
                    logicStatment = logicStatment.replace(/\[/g, "(");
                    logicStatment = logicStatment.replace(/\]/g, ")");

                    // replace "|" and "&" statement with evaluable logical operator (|| and &&)
                    logicStatment = logicStatment.replace(/\&/g, "&&");
                    logicStatment = logicStatment.replace(/\|/g, "||");

                    // empty parenthesis must be evaluated has true
                    logicStatment = logicStatment.replace(/\(\)/g, "(true)");

                    result = eval(logicStatment);
                } catch (error) {
                    console.error('We were unable to evaluate the following option logic statement: ' + optionLogic + '\n', error);
                }
            } else {
                result = true;
            }
            return result;
        }

        function round(value, decimals) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
        }


        /*
         * Test section
         */
        function testBuildCalculatorData() {
            let programJson = {
                "acode": "CAC70RMT11CA2",
                "finance": [
                    {
                        "term": {
                            "36": "5.99",
                            "48": "5.99",
                            "60": "5.99",
                            "72": "5.99",
                            "84": "5.99",
                            "96": "5.99"
                        }
                    },
                    {
                        "term": {
                            "36": "5.99",
                            "48": "5.99",
                            "60": "5.99",
                            "72": "5.99",
                            "84": "4.99",
                            "96": "4.99"
                        }
                    }
                ],
                "lease": [
                    {
                        "term": {
                            "24": "6.09",
                            "36": "6.09",
                            "39": "6.09",
                            "48": "6.09",
                            "51": "6.09",
                            "60": "6.09"
                        }
                    },
                    {
                        "optionlogic": "[(22A-*|25A-*)]",
                        "term": {
                            "24": "5.0",
                            "36": "5.0",
                            "39": "8.0",
                            "48": "6.0",
                            "51": "8.0",
                            "60": "9.0"
                        }
                    }
                ],
                "residual": [
                    {
                        "optionlogic": "22M-*",
                        "term": {
                            "24": "63.0",
                            "36": "55.0",
                            "39": "54.0",
                            "48": "48.0",
                            "51": "47.0",
                            "60": "40.0"
                        },
                        "mileage": "16K"
                    },
                    {
                        "optionlogic": "22M-*",
                        "term": {
                            "24": "60.0",
                            "36": "52.0",
                            "39": "51.0",
                            "48": "45.0",
                            "51": "44.0",
                            "60": "37.0"
                        },
                        "mileage": "24K"
                    },
                    {
                        "optionlogic": "22M-*",
                        "term": {
                            "24": "63.0",
                            "36": "55.0",
                            "39": "54.0",
                            "48": "48.0",
                            "51": "47.0",
                            "60": "40.0"
                        },
                        "mileage": "12K"
                    },
                    {
                        "optionlogic": "22M-*",
                        "term": {
                            "24": "61.0",
                            "36": "53.0",
                            "39": "52.0",
                            "48": "46.0",
                            "51": "45.0",
                            "60": "38.0"
                        },
                        "mileage": "20K"
                    },
                    {
                        "optionlogic": "22M-*",
                        "term": {
                            "24": "1.0",
                            "36": "70.0",
                            "39": "52.0",
                            "48": "46.0",
                            "51": "80.0",
                            "60": "38.0"
                        },
                        "mileage": "20K"
                    }
                ]
            };
            $service.buildCalculatorData(programJson, ['22A', '26B', 'AGR', '22M']);
        }


        function testOptionLogicEvaluation() {
            let optionLogicList = ["22G-*",
                "26G-*",
                "(24J-*|26J-*)",
                "(24F-*|26F-*)",
                "![DF5-*]",
                "DF5-*",
                "(23E-*|23G-*|23T-*|23Y-*|24E-*|24G-*|24T-*|24Y-*)",
                "(23H-*|24H-*)",
                "(ERC-*|EZH-*)",
                "EXF-*",
                "(23H-*|28H-*)",
                "(23K-*|28K-*)",
                "(21J-*|27J-*)",
                "(21L-*|27L-*)",
                "23F-*",
                "(23E-*|23Z-*)",
                "(24J-*|25J-*|26J-*|27J-*)",
                "(24F-*|25F-*|26F-*|27F-*)",
                "(23Q-*|23S-*|24Q-*|24S-*)",
                "(23W-*|24W-*)",
                "(23B-*|23Q-*|23S-*|24B-*|24Q-*|24S-*)",
                "(23B-*|23S-*|24B-*|24S-*)",
                "(24A-*|26A-*)",
                "(24G-*|26G-*)",
                "(24P-*|26P-*)",
                "(24N-*|26N-*)",
                "(24G-*|25G-*|26G-*|27G-*)",
                "(24P-*|25P-*|26P-*|27P-*)",
                "(22P-*|28P-*|2BP-*|2CP-*)",
                "(22S-*|28S-*|2BS-*|2CS-*)",
                "(22H-*|28H-*|2BH-*)",
                "(22D-*|28D-*|2BD-*)",
                "(21L-*|2EL-*)",
                "(21J-*|2EJ-*)",
                "(23S-*|24S-*)",
                "2BZ-*",
                "2BE-*",
                "![AGR-*]",
                "(25B-*|26B-*|28B-*)",
                "AGR-*",
                "(EZF-*|ESA-*|EZC-*)",
                "ETK-*",
                "(26X-*|28X-*)",
                "(26G-*|28G-*)",
                "(26T-*|28T-*)",
                "(22K-*|26K-*|2FK-*)",
                "(22M-*|26M-*|2FM-*)",
                "![27L-*]",
                "![27Q-*]",
                "[(22A-*|25A-*)]&![AGR-*]",
                "(25J-*|26J-*|AGR-*)",
                "(22B-*|25B-*|26B-*|28B-*)",
                "![26L-*]",
                "(26V-*|28V-*)",
                "![26Q-*]",
                "(26K-*|28K-*)",
                "(22G-*|26G-*|2EG-*|2FG-*)",
                "(22T-*|26T-*|2FT-*)",
                "(22K-*|26K-*|28K-*|2FK-*)",
                "(AGR-*|WLA-*)",
                "(22M-*|26M-*|28M-*|2FM-*)",
                "(22G-*|26G-*|28G-*)",
                "(22T-*|26T-*|28T-*)",
                "(22A-*|25A-*|AGR-*)",
                "(22A-*|AGR-*)",
                "(22B-*|25B-*|27B-*|28B-*)",
                "(22H-*|26H-*|2EH-*|2FH-*)",
                "22J-*",
                "(25B-*|26B-*)",
                "(22G-*|26G-*|27G-*|2EG-*|2FG-*)",
                "26T-*",
                "26X-*",
                "![26L-*|AMB-*|AVH-*]",
                "AVH-*",
                "![26Q-*|AMB-*|AVH-*]",
                "26K-*",
                "AMB-*",
                "26V-*",
                "(22G-*|27G-*|28G-*)",
                "(22T-*|27T-*|28T-*)",
                "(27X-*|28X-*)",
                "(22J-*|25J-*|27J-*|AGR-*)",
                "(26V-*|27V-*|28V-*)",
                "(26K-*|27K-*|28K-*)",
                "(26H-*|27H-*|28H-*)",
                "(22J-*|25J-*|27J-*)",
                "27Q-*",
                "(27L-*|28L-*)",
                "(26L-*|27L-*|28L-*)",
                "(22B-*|25B-*|26B-*|27B-*|28B-*)",
                "(26Q-*|27Q-*)",
                "(25J-*|26J-*|27J-*)",
                "(22T-*|26T-*|27T-*|28T-*)",
                "(26X-*|27X-*|28X-*)",
                "(22G-*|26G-*|27G-*|28G-*)",
                "(27H-*|28H-*)",
                "[25A-*]&![AGR-*]",
                "(25A-*|AGR-*)",
                "(25B-*|26B-*|27B-*|28B-*)",
                "(26G-*|27G-*|28G-*)",
                "(26T-*|27T-*|28T-*)",
                "27L-*",
                "22F-*",
                "(29G-*|29P-*)",
                "29E-*",
                "29K-*",
                "29L-*",
                "29S-*",
                "29N-*",
                "(22D-*|28D-*)",
                "(22S-*|28S-*)",
                "(22Q-*|28Q-*)",
                "21W-*",
                "21Y-*",
                "(23G-*|24G-*)",
                "(23Y-*|24Y-*)",
                "(23X-*|24X-*)",
                "21A-*",
                "21V-*",
                "22S-*",
                "22M-*",
                "(22G-*|22X-*)"
            ];
            for (x = 0; x < optionLogicList.length; x++) {
                optionList = ['22A-1', '26B-2'];
                evaluateOptionLogic(optionLogicList[x], optionList);
            }
        }
        // TODO Remove when sni support Or Programs
        // TODO ------------------------------LEGACY METHODS-----------------------------
        $service.getProgramsLegacy = (modelYearId, provinceCode) => {
            if (!modelYearId || !provinceCode) {
                // If we don't have the data to make the call we return an empty promise
                // to catch instead of printing JS errors.
                return Promise.resolve();
            }

            let deferredObject = $q.defer();

            $http({
                method : "GET",
                url : "/api/buildandprice/program/modelYear/"+ modelYearId +"?provinceCode=" + provinceCode,
                cache : true
            }).then(response => {
                    deferredObject.resolve(response);
                },
                response => {
                    deferredObject.reject(response);
                });

            return deferredObject.promise;
        }
    }
})(angular);
