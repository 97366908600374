(function(ng) {
	function isModuleAvailable(name) {
		try {
			return angular.module(name).name === name;
		} catch (e) {
			return false;
		}
	}

	angular
		.module('fca.brandApp')
		.controller('brandAppMainController', MainController);

	/**
	 * @ngdoc controller
	 * @name fca.brandApp.controller:brandAppMainController
	 */
	function MainController(
		$http,
		$rootScope,
		$scope,
		gtmAnalytics,
		fcaGeolocator,
		cookieLocation,
		userLocation,
		geolocatorAutoDetection,
		storageService,
		focusFunctions,
		$timeout,
		$compile) {
		'ngInject';

		// Default arguments to invoke geolocation method
		let args = [];

		/**
		 * @ngdoc property
		 * @name location
		 * @description User location object
		 * @type {Object}
		 */
		this.location = null;

		$scope.$watch('$mainCtrl.location', function(newValue) {
			if (newValue) {
				document.dispatchEvent(new CustomEvent("EVENT.geolocation.locationChanged", {
					detail: newValue
				}))
			}
		});

		// Listen to this event to compile angular element (event send from vuejs)
		document.addEventListener('fca-compile-element', (e) => {
			$compile(e.detail.el)($rootScope);
		});

		this.initialize = () => {

			focusFunctions.init();

			this.isCookiesEnabled = storageService.isCookiesEnabled();

			/* eslint-disable prefer-spread */
			if (cookieLocation.isCookieExists()) {
				this.location = cookieLocation.getLocation();
			}

			// if we don't have a location, use the default one until we geo-locate the user
			if (this.location == null) {
				this.location = fcaGeolocator.getDefaultLocation();
			}
			// add data-attribute on the body to get access to the location name
			$('body').attr('data-location-province', this.location.province);

			let dealerId;
			this.hashParameters = getHashParameters();
			if (this.hashParameters.dealerId && this.hashParameters.dealerId !== 'null') {
				sessionStorage.setItem('dealerId', this.hashParameters.dealerId);
				dealerId = this.hashParameters.dealerId;
			} else {
				dealerId = storageService.sessionStorageGetItem('dealerId');
			}

			// check if we have a dealer website vendor
			if (this.hashParameters.vendor && this.hashParameters.vendor !== 'null') {
				sessionStorage.setItem('vendor', this.hashParameters.vendor);
			}

			// check if we have a dealer website url
			if (this.hashParameters.dealerUrl && this.hashParameters.dealerUrl !== 'null') {
				sessionStorage.setItem('dealer-url', this.hashParameters.dealerUrl);
			}

			const eventChangeName = fcaGeolocator.getLocationChangedEvent();
			$scope.$on(eventChangeName, (evt, args) => {
				// Location
				let [location] = args;

				$('.js-vehiclerecommender').trigger({
					type: 'vehicle-recommender-event:location-change',
					lat: args[0].latitude,
					long: args[0].longitude,
					postalCode: args[0].postalCode,
				});

				this.location = location;

				// Set new location in cookie
				cookieLocation.setLocation(location);

				//  Update location on all location status component
				let eventUpdateName = fcaGeolocator.getLocationUpdatedEvent();
				$scope.$broadcast(eventUpdateName, [location]);
				$('body').attr('data-location-province', this.location.province);
			});

			// This condition is for the iframe mode of the build and price,
			if (dealerId) {
				const dealerKey = 'dealer-lock';
				const dealerProvinceKey = 'dealerProvince';

				// retrieve the dealer from session storage
				let dealerFromStorage = JSON.parse(sessionStorage.getItem(dealerKey));

				/**
				 * once the dealer is geo-coded, change the user's location to the same as the dealer
				 */
				let dealerProvinceLoaded = function(dealerProvince) {
					userLocation.changeLocation(dealerProvince, true);
				};

				/**
				 * once the dealer object is retrieved, get the dealer's geo-location
				 */
				let dealerLoaded = function(dealer) {
					let dealerProvince = JSON.parse(sessionStorage.getItem(dealerProvinceKey));
					if (dealerProvince) {
						dealerProvinceLoaded(dealerProvince);
					} else {
						let province = dealer.province;
						fcaGeolocator.geocodeWithPostalCode(userLocation.getDefaultPostalCodeForProvince(province)).then((location) => { // eslint-disable-line max-len
							dealerProvince = {'region': `${location.province},${location.region}`};
							sessionStorage.setItem(dealerProvinceKey, JSON.stringify(dealerProvince));
							dealerProvinceLoaded(dealerProvince);
						});
					}
				};

				if (dealerFromStorage) {
					dealerLoaded(dealerFromStorage);
				} else {
					// if we don't have the dealer from storage, retrieve it
					let brandCode = FCA_SITES_CONFIG.name;
					$http.get(`/data/dealers/` + brandCode + `/` + dealerId).then((result) => {
						if (result.data) {
							let dealerObject = result.data;
							// save the dealer in session storage
							sessionStorage.setItem(dealerKey, JSON.stringify(dealerObject));
							dealerLoaded(dealerObject);
						} else {
							console.error("could not retrieve dealer " + dealerId);
						}
					});
				}
			} else {
                // only trigger auto-detection if we're not in dealer mode (iframe)
				// this will trigger a location changed event (not location updated, that would be too simple)
				geolocatorAutoDetection.detect(function(location) {
					// setting the user location is done directly in the callback because the user location emits a change location event
					userLocation.setLocation(location);
				});
			}

			// If the list of nearest dealers has instances of chat availability, broadcast it to other components.
			$scope.$on('nearestDealersWithChatObtained', (evt, args) => {
				$('body').attr('data-page-has-chat-popup', 'true');
				$scope.$broadcast('nearestDealersWithChatUpdated');
			});

			// If the list of nearest dealers has no instances of chat availability, broadcast it to other components.
			$scope.$on('noNearestDealersWithChatObtained', (evt, args) => {
				$('body').attr('data-page-has-chat-popup', 'false');
				$scope.$broadcast('noNearestDealersWithChatUpdated');
			});

			// Upon request, close overlays and lightboxes and show the dealer chat popup
			$scope.$on('requestDealersChatPopup', (evt, args) => {
				$scope.$broadcast('openDealersChatPopup');
			});

			$rootScope.$on('$translateLoadingEnd', (evt, args) => {
				$('body').removeClass('translate-loading');
			});

			// expose ourself to the window.fca namespace (debug purpose mostly)
			window.fca = window.fca || {};
			window.fca.brandAppMainController = this;

			return this;
		};

		/**
		 * Safe scope apply method
		 */
		this.scopeApply = () => {
			$timeout(() => {
				$scope.$apply();
			});
		};

		gtmAnalytics.trackPage();
		this.initialize();
	}

	angular.element(function() {
		// this module is only loaded on the /en/compare page
		// if this module doesn't exist we create an empty module (not to break the bootstrap)
		if (!isModuleAvailable('fca.core.competitionCompare')) {
			angular.module('fca.core.competitionCompare', []);
		}

		angular.bootstrap(document, ['fca.brandApp']);
	});
})();
