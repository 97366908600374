/* eslint-disable indent */
(function() {
	angular
		.module('fca.cookieBanner')
		.component('fcaCookieBanner', {
			controller: FcaCookieBannerController,
			controllerAs: '$ctrl',
			templateUrl: '/cookie-banner/fca-cookie-banner.html',
			bindings: {
				image: '@',
				imageMobile: '@',
				title: '@',
				description: '@'
			}
		});

	function FcaCookieBannerController($scope, storageService, $timeout, $cookies, $rootScope, $element) {
		'ngInject';

		const $ctrl = this;
		const $body = $('body');

		$ctrl.showBanner = false;
		$ctrl.isFading = false;
		$ctrl.isCompletelyFaded = false;
		$ctrl.brand = FCA_SITES_CONFIG.name;

		$ctrl.$onInit = () => {
			if (storageService.isCookiesEnabled()) {
				$ctrl.checkCookies();
			}
		};

		$ctrl.retrieveLinkElement = () => {
			let privacyPolicyLink = $element.find('.CB_privacy-content-link');
			$rootScope.$broadcast('cookie-banner-overlay', privacyPolicyLink);
		};

		$ctrl.checkCookies = () => {
			const cookies = $cookies.getAll();
			if (angular.equals(cookies, {})) {
				$ctrl.showBanner = true;
			} else {
				$ctrl.checkLocalStorage();
			}
		};

		$ctrl.checkLocalStorage = () => {
			if (localStorage.getItem('cookieBannerUpdated') === null) {
				$ctrl.checkSessionStorage();
				$timeout(() => {
					$ctrl.manageDirectorFocus();
					$ctrl.createDirectorObserver();
				});
			} else {
				$ctrl.showBanner = false;
				$ctrl.unSuppressOtherPopups();
			}
		};

		$ctrl.checkSessionStorage = () => {
			if (sessionStorage.getItem('cookieBannerUpdated') === null) {
				$ctrl.suppressOtherPopups();
				$ctrl.showBanner = true;
			} else {
				$ctrl.showBanner = false;
				$ctrl.unSuppressOtherPopups();
			}
		};

		$ctrl.setLocalStorage = () => {
			const director = $body.find('#zones_director-steps_blocks_director');
			const directorInteractiveElements = director.find('a, button, input, select, textarea');

			$ctrl.showBanner = false;
			$ctrl.unSuppressOtherPopups();
			localStorage.setItem('cookieBannerUpdated', 'true');
			directorInteractiveElements.removeAttr('tabindex');
		};

		$ctrl.setSessionStorage = () => {
			sessionStorage.setItem('cookieBannerUpdated', 'true');
		};

		$ctrl.setBackgroundImage = () => {
			if ($ctrl.brand === 'alfaromeo') {
				return $ctrl.image;
			} else {
				return '';
			}
		};

		$ctrl.suppressOtherPopups = () => {
			$body.find('#zones_body_blocks_sign-up-now').hide();
			$body.find('.chat-popup').hide();
			$body.find('#zones_search_blocks_search-ready-cta').hide();
			$rootScope.$on('cookie-sticky-init', () => {
				$body.find('.sticky-wrapper').hide();
			});
		};

		$ctrl.unSuppressOtherPopups = () => {
			$body.find('#zones_body_blocks_sign-up-now').show();
			$body.find('.chat-popup').show();
			$body.find('#zones_search_blocks_search-ready-cta').show();
			$body.find('.sticky-wrapper').show();
		};

		$ctrl.createDirectorObserver = () => {
			const director = $body.find('#zones_director-steps_blocks_director .C_SB-holder')[0];
			const options = { attributes: true, attributeFilter: ['class'] };
			const observer = new MutationObserver($ctrl.manageDirectorFocus);

			if (director) {
				observer.observe(director, options);
			}
		}

		$ctrl.manageDirectorFocus = () => {
			const director = $body.find('#zones_director-steps_blocks_director');
			const isStuck = $('#zones_director-steps_blocks_director .stuck').length > 0;
			const directorInteractiveElements = director.find('a, button, input, select, textarea');

			if (localStorage.getItem('cookieBannerUpdated') !== null) {
				observer.disconnect();
			}

			if (isStuck) {
				directorInteractiveElements.attr('tabindex', '-1');
			} else {
				directorInteractiveElements.attr('tabindex', '0');
			}
		};
	}
})();
