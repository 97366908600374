(function() {
    angular
        .module('fca.commonDirectives')
        .directive('buildAndPriceNavUrl', buildAndPriceNavUrl)
        .controller('buildAndPriceNavUrlController',BuildAndPriceNavUrlController);

    function buildAndPriceNavUrl() {
        return {
            controller: BuildAndPriceNavUrlController,
            controllerAs: 'BuildAndPriceNavUrlCtrl',
            bindToController: {
                menuLinkedNameplateCodeList: '@',
                baseUrl: '@',
                linkIcon: '@'
            }
        };
    }

    function BuildAndPriceNavUrlController($element) {
        'ngInject';

        this.isChecked = false;

        this.$onInit = () => {

            let menuLinkedNameplateCodeList = this.menuLinkedNameplateCodeList;
            let linkIcon = this.linkIcon;
            let array = this.mySplit(menuLinkedNameplateCodeList);
            let sameNameplateCode = false;
            let nameplateCode = window.FCA_SITES_CONFIG.nameplateCode;
            let pageCode = window.FCA_SITES_CONFIG.pageCode;
            let showBuildAndPriceLink = window.FCA_SITES_CONFIG.showBuildAndPriceLink;
            let nameplateWithCustomBuildAndPriceUrl = window.FCA_SITES_CONFIG.customBuildAndPriceUrl;
            if(showBuildAndPriceLink == 'false') {
                showBuildAndPriceLink = false;
            } else {
                showBuildAndPriceLink = true;
            }

            if(nameplateWithCustomBuildAndPriceUrl == 'true') {
                nameplateWithCustomBuildAndPriceUrl = true;
            } else {
                nameplateWithCustomBuildAndPriceUrl = false;
            }

            array.forEach(function (value) {
                if(nameplateCode == value) {
                    sameNameplateCode = true;
                }
            });

            // this condition check if we are on the buildAndPrice icon in the main menu bar, check if we are on any of those three pages (vlp models gallery)
            // and check if we have a nameplate that we dont want a custom url for the buildAndPrice mini nav
            if(showBuildAndPriceLink && linkIcon == "fcaicon-build-and-price" && !sameNameplateCode && ( pageCode == "vlp" || pageCode == "models" || pageCode == "gallery")) {
                let baseUrl = this.baseUrl;
                let modelYearId = window.FCA_SITES_CONFIG.modelYearId;
                let brandCode = window.FCA_SITES_CONFIG.name;
                let year = window.FCA_SITES_CONFIG.year;
                let modelYearVisible = window.FCA_SITES_CONFIG.isVisibleOnBuildAndPrice;
                let isEmpty = (brandCode !== "" || nameplateCode !== "" || year !== "" || modelYearId !== "" );
                if(isEmpty && !nameplateWithCustomBuildAndPriceUrl && modelYearVisible == 'true') {
                    let buildAndPriceUrl = `${baseUrl}/${brandCode}/${nameplateCode}/${year}/${modelYearId}`;
                    $element[0].href=buildAndPriceUrl;
                } else if(nameplateWithCustomBuildAndPriceUrl) {
                    let customUrl = `${baseUrl}/${brandCode}/${nameplateCode}#activeTab=cash&year=${year}`;
                    $element[0].href=customUrl;
                } else {
                    $element[0].href=baseUrl;
                }

            }

        };

       this.mySplit = function(string) {
            var array = string.split(',');
            return array;
        }
    }
})();

