const affiliateIdName = "affiliateId";
/**
 * save custom order endpoint
 */
 const saveCustomOrderAPI = '/data/custom-order/insert';

function getHashParameters() {
  let hashParameters = {};
  try {
    const hash = location.hash.substr(1);
    hashParameters = parseQueryString(hash);
  } catch (exception) {
    console.error("error parsing hash parameters", exception);
  }
  return hashParameters;
}

function getSearchParameters() {
  return new URLSearchParams(window.location.search);
}

/**
 * If multiple parameters have same key, the last value will be used.
 */
function parseQueryString(queryString) {
  let result = {};
  try {
    if (queryString && queryString.length > 0) {
      // ignore any leading question mark
      const toParse =
        queryString[0] === "?" ? queryString.substr(1) : queryString;
      let vars = toParse.split("&");
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair && pair.length > 0) {
          let key = decodeURIComponent(pair[0]);
          // If a parameter has no value, the value is blank.
          let value = decodeURIComponent(pair.length > 1 ? pair[1] : "");
          result[key] = value;
        }
      }
    }
  } catch (exception) {
    console.error("error parsing query string", exception);
  }
  return result;
}

/**
 * produces a query string from the parameters (in the form key=value)
 */
function toQueryString(parameters) {
  let result = "";
  try {
    if (parameters) {
      Object.keys(parameters).forEach(function (key) {
        if (result.length > 0) {
          result += "&";
        }
        result +=
          encodeURIComponent(key) + "=" + encodeURIComponent(parameters[key]);
      });
    }
  } catch (exception) {
    console.error("error producing query string", exception);
  }

  return result;
}

/**
 * takes the current query string and adds it to the URL in parameter
 */
function addQueryParametersToUrl(URL) {
  let result = URL;
  try {
    let queryString = location.search;
    if (queryString) {
      result += queryString;
    }
  } catch (exception) {
    console.error("error adding current query string to url", exception);
  }
  return result;
}

/**
 * adds all current hash parameters to the url except parameters included into parametersToIgnore
 */
function addHashparamsToUrl(URL, parametersToIgnore) {
  let result = URL;
  try {
    const hashParameters = getHashParameters();
    if (hashParameters) {
      if (parametersToIgnore && parametersToIgnore.length) {
        parametersToIgnore.forEach((element) => delete hashParameters[element]);
      }
      result += "#" + toQueryString(hashParameters);
    }
  } catch (exception) {
    console.error("error adding current hash parameters to url", exception);
  }
  return result;
}

function postProcessUrl(hashParameters, activeTab) {
  let result = null;
  try {
    let filteredHashParameters = $.extend(hashParameters, {});
    // remove the year, we don't want it to propagate
    if (filteredHashParameters.year) {
      delete filteredHashParameters.year;
    }

    if (filteredHashParameters.step == "models") {
      delete filteredHashParameters.step;
    }

    if (activeTab) {
      filteredHashParameters.activeTab = activeTab;
    }
    result = "#" + toQueryString(filteredHashParameters);
  } catch (exception) {
    console.error("error post processing url", exception);
  }
  return result;
}

/**
 * @returns the value of the key `affiliateId` in the session storage
 */
function getStoredAffiliateId() {
  return sessionStorage.getItem(affiliateIdName);
}

/**
 * Will store the affiliate id in the session storage, mapped to `affiliateId` key
 * @param affiliateId - The value to store
 */
function storeAffiliateId(affiliateId = getAffiliateIdParam()) {
  if (!getStoredAffiliateId() && isAffiliateMode(affiliateId))  {
    sessionStorage.setItem(affiliateIdName, affiliateId);
  }
}

/**
 * @returns the value of affiliate id in the url. Order of search:
 *  `userGroupId` in search paramerters > `affiliateId` in search paramerters > `userGroupId` in hash paramerters > `affiliateId` in hash paramerters
 */
function getAffiliateIdParam() {
  try {
    const userGroupIdName = "userGroupId";
    const urlSearchParameters = getSearchParameters();
    const urlHashParameters = getHashParameters();
    return urlSearchParameters.get(userGroupIdName) || urlSearchParameters.get(affiliateIdName) || urlHashParameters[userGroupIdName] || urlHashParameters[affiliateIdName];
  } catch (exception) {
    console.error("error getting affiliate Id Parameters", exception);
  }
}

/**
 * @param affiliateId
 * @returns true if the affiliate id is valid.
 */
function isAffiliateMode(affiliateId = getStoredAffiliateId() || getAffiliateIdParam()) {
return ['ChryslerEmployees', 'PreferredFriends', 'PreferredCustomer', 'AffiliateOther'].includes(affiliateId);
}

function getAffiliateIdUrlParam(prefix = '&') {
  const affiliateId = getStoredAffiliateId();
  return affiliateId ?  `${prefix}${affiliateIdName}=${affiliateId}` : '';
}

/**
 * @param param
 * @returns the requested param from url or session
 */
function getAffiliateParam(param) {
  const urlParams = getSearchParameters();
  return urlParams.get(param) || sessionStorage.getItem(param) || null
}

/**
 * @param param
 * @returns the affiliate informations to save a custom order
 */
function getAffiliateCustomOrder() {
  return [getStoredAffiliateId(), getAffiliateParam('affiliateEncryptedParam'), getAffiliateParam('affiliateEaProgram')];
}

/**
 * @returns the pricing to be send inside the payload to save custom order
 */
 function pricingCustomOrder(netAmount, paymentMethod, financeTerm, financeRate, financeFrequency, leaseTerm, leaseRate, leaseFrequency, downPayment, tradeIn, oweOnTrade, options, incentives) {
  return {
    netAmount: netAmount,
    paymentMethod: paymentMethod,
    financeTerm: financeTerm,
    financeRate: financeRate,
    financeFrequency: financeFrequency,
    leaseTerm: leaseTerm,
    leaseRate: leaseRate,
    leaseFrequency: leaseFrequency,
    downPayment: downPayment,
    tradeIn: tradeIn,
    oweOnTrade: oweOnTrade,
    options: options,
    incentives: incentives,
  };
}

/**
 * @returns the body to be sent as payload to save custom order
 */
 function payloadCustomOrder(region, year, brandCode, nameplateCode, language, affiliateId, affiliateEncryptedParam, affiliateEaProgram, campaignCode, trim, labelEn, labelFr, acode, modelYearId, modelCode, persistentUid, packageCode, scratchSave, imageURL, wheelImageURL,vehicleColorImageURL,engineImageURL, transmissionImageURL, options, dealerCode, buildId, pricing) {
  return {
    region: region,
    year: year,
    brandCode: brandCode,
    nameplateCode: nameplateCode,
    language: language,
    affiliateId: affiliateId,
    affiliateEncryptedParam: affiliateEncryptedParam,
    affiliateEaProgram: affiliateEaProgram,
    campaignCode: campaignCode,
    trim: trim,
    labelEn: labelEn,
    labelFr: labelFr,
    acode: acode,
    modelYearId: modelYearId,
    modelCode: modelCode,
    persistentUid: persistentUid,
    packageCode: packageCode,
    scratchSave: scratchSave,
    imageURL: imageURL,
    wheelImageURL: wheelImageURL,
    vehicleColorImageURL: vehicleColorImageURL,
    engineImageURL: engineImageURL,
    transmissionImageURL: transmissionImageURL,
    options: options,
    dealerCode: dealerCode,
    buildId: buildId,
    pricing
  };
}

/**
 * @returns the dealer page url
 */
 function redirectToDealersPage(language, buildId, persistentUid) {
  const pid = persistentUid && `&pid=${persistentUid}`;
  const customOrder = hasCustomOrderParam() ? '&customOrder=true' : '';
  window.location.href = `/${language}/build-and-price/find-a-dealer?buildId=${buildId}${pid}${customOrder}`
}

function getCustomOrderParam() {
	const param = 'customOrder';
	const urlSearchParameters = getSearchParameters();
    const urlHashParameters = getHashParameters();
	return urlHashParameters[param] || urlSearchParameters.get(param) || sessionStorage.getItem('customOrder') || null;
  }

function storeCustomOrderParam() {
	if (getCustomOrderParam() === 'true')  {
		sessionStorage.setItem('customOrder', 'true');
	} else {
		sessionStorage.removeItem('customOrder');
	}
}

function hasCustomOrderParam() {
	return getCustomOrderParam() === 'true' || sessionStorage.getItem('customOrder') === 'true';
}

function cleanCustomOrderParam() {
	const urlSearchParameters = getSearchParameters();
    const urlHashParameters = getHashParameters();
	if (!urlHashParameters['customOrder'] && !urlSearchParameters.get('customOrder')) sessionStorage.removeItem('customOrder');
}
