/**
 * Directive to manage the Animated Hero Banner
 * - Manage Analytics
 * - Manage modal opening (todo)
 */
(function() {
    angular
        .module('fca.commonDirectives')
        .directive('fcaAnimatedHeroBanner', fcaAnimatedHeroBanner);

    function fcaAnimatedHeroBanner() {
       return {
            restrict: 'A',
            controllerAs: '$animatedHeroBannerCtrl',
            controller: FcaAnimatedHeroController
        };

        function FcaAnimatedHeroController() {
            'ngInject';
            const $ctrl = this;

            $ctrl.$onInit = () => {
                const animatedHeroElement = document.querySelector('ncl-animated-hero');
                if(animatedHeroElement) {
                    animatedHeroElement.addEventListener('fca:animated-hero:click', event => {
                        const analyticsHiddenButton = document.createElement('button');
                        analyticsHiddenButton.ariaHidden = 'true';
                        analyticsHiddenButton.className = 'animated-hero-banner__analytic-button';
                        analyticsHiddenButton.setAttribute('data-analyticsid', 'hero-banner-cta-');
                        analyticsHiddenButton.setAttribute('data-analyticsvalue', event.detail.buttonName + ' - open');
                        animatedHeroElement.appendChild(analyticsHiddenButton);
                        analyticsHiddenButton.click();
                        animatedHeroElement.removeChild(analyticsHiddenButton);
                        // TODO - manage with correct value when nemaplate selector will be implemented
                        emitNameplateChanges('wranglerjl', '2023');
                    })
                }
                // TODO - manage with correct value when nemaplate selector will be implemented
                emitNameplateChanges('grandcherokee4xe', '2023')
            };
        }

        function emitNameplateChanges(nameplateCode, modelYear) {
            document.dispatchEvent(new CustomEvent("EVENT.evTools.nameplate", {
                detail: {nameplateCode, modelYear}
            }))
        }
    }


})();
