(function () {
    angular
    .module('fca.applyForFinancing', ['ngMessages'])
    .directive('fcaApplyForFinancing', FcaApplyForFinancing);

    function FcaApplyForFinancing() {
        return {
            restrict: 'A',
            scope: true,
            bindToController: {
                apiUrl: '@',
                location: "<",
                calculatorSliderStepSize: '<',
                calculatorDefaultFinanceRate: '<',
                languageLink: '@',
                disclaimers: '<'
            },
            controllerAs: '$aFFCtrl',
            controller: FcaApplyForFinancingController
        };

        function FcaApplyForFinancingController($scope, $rootScope, $http,
            $location, $timeout, $element, $sce, fcaScrollToElementService,
            gtmAnalytics, matchmedia, FCA_MQ_LAYOUT) {
            'ngInject';

            const $aFFCtrl = this;

            $aFFCtrl.provinceList = FCA_SITES_CONFIG.location;
            $aFFCtrl.brandCode = FCA_SITES_CONFIG.name;
            $aFFCtrl.language = FCA_SITES_CONFIG.language;

            $aFFCtrl.showIdentificationStep = true;
            $aFFCtrl.showVehicleSelectorAndFinanceStep = false;
            $aFFCtrl.showIframe = false;
            $aFFCtrl.isBuildAndPriceMode = false;
            $aFFCtrl.loaded = false;

            $aFFCtrl.selectedDealer = '';
            $aFFCtrl.dealerTrackDealerId = '';
            $aFFCtrl.selectedNameplateName = '';
            $aFFCtrl.selectedBrandName = '';
            $aFFCtrl.selectedNameplate = '';
            $aFFCtrl.selectedTrim = '';
            $aFFCtrl.selectedYear = '';
            $aFFCtrl.selectedModelYearId = '';
            $aFFCtrl.selectedMfgCode = '';
            $aFFCtrl.selectedAcode = '';
            $aFFCtrl.scratchSave = '';
            $aFFCtrl.jellyUrl = '';
            $aFFCtrl.vin = '';
            $aFFCtrl.provinceCode = '';
            $aFFCtrl.financeMode = '';
            $aFFCtrl.frequency = '';
            $aFFCtrl.downpayment = 0;
            $aFFCtrl.tradeInValue = 0;
            $aFFCtrl.oweOnTrade = 0;
            $aFFCtrl.basePriceAndOptions = 0;
            $aFFCtrl.financeNet = 0;
            $aFFCtrl.financePayment = 0;
            $aFFCtrl.financeTerm = 0;
            $aFFCtrl.financeRate = 0;
            $aFFCtrl.monthlyEstimate = 0;
            $aFFCtrl.leaseTerm = 0;
            $aFFCtrl.leaseRate = 0;
            $aFFCtrl.leaseResidual = 0;
            $aFFCtrl.leaseMileage = 0;
            $aFFCtrl.leasePayment = 0;
            $aFFCtrl.incentivesTotal = 0;
            $aFFCtrl.financeDiscountTotalAfterTax = 0;
            $aFFCtrl.financeDiscountTotalBeforeTax = 0;
            $aFFCtrl.otherFees = 0;

            $aFFCtrl.vehicleChanged = true;

            $aFFCtrl.postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            $aFFCtrl.phoneRegex = /^\(?([0-9]{3})\)?[. ]?([0-9]{3})[ -. ]?([0-9]{4})$/;

            $aFFCtrl.formErrors = [];
            $aFFCtrl.sniVehicle = {};
            $aFFCtrl.applyForFinancingParameters = {};

            $aFFCtrl.errorMsgVisible = false;
            $aFFCtrl.vehicleChosen = false;

            $aFFCtrl.iframeHeight = 0;
            $aFFCtrl.vlpUrl = '';

            $aFFCtrl.pcaControl;
            $aFFCtrl.applyForFinancing = {
                title: '',
                firstName: null,
                lastName: '',
                email: '',
                phone: '',
                addressOne: '',
                addressTwo: '',
                city: '',
                province: '',
                postalCode: '',
                lang: 'en',
                sourceCode: 'WS'
            };

            $aFFCtrl.$onInit = () => {
                $aFFCtrl.applyHashParameters();

                $scope.$on('calculator:update-payment', (_, data) => {
                    $aFFCtrl.downPaymentValue = data.downPayment
                        ? data.downPayment.toString() : '';
                    $aFFCtrl.tradeValue = data.tradeInValue
                        ? data.tradeInValue.toString() : '';
                    $aFFCtrl.tradeOwed = data.tradeOwed.toString();
                    $aFFCtrl.financeRate = data.financeRate;
                    $aFFCtrl.financeTerm = data.financeTerm;
                    $aFFCtrl.financeNet = data.netAmount;
                    $aFFCtrl.financePayment = data.financePayment;
                    $aFFCtrl.frequency = data.frequency;
                    $aFFCtrl.leaseTerm = data.leaseTerm;
                    $aFFCtrl.leaseRate = data.leaseInterestRate;
                    $aFFCtrl.leasePayment = data.leasePayment;
                    $aFFCtrl.leaseResidual = data.leaseResidualValue;
                    $aFFCtrl.leaseMileage = data.leaseMileage;
                    $aFFCtrl.financeMode = data.financeMode;
                });

                if (!!sessionStorage.getItem('applyForFinancingParameters')) {
                    $aFFCtrl.applyForFinancingParameters = JSON.parse(
                        sessionStorage.getItem('applyForFinancingParameters'));
                    $aFFCtrl.selectedNameplate = $aFFCtrl.applyForFinancingParameters.nameplateCode;
                    $aFFCtrl.selectedTrim = $aFFCtrl.applyForFinancingParameters.trimName;
                    $aFFCtrl.selectedYear = $aFFCtrl.applyForFinancingParameters.year;
                    $aFFCtrl.selectedModelYearId = $aFFCtrl.applyForFinancingParameters.modelYearId;
                    $aFFCtrl.selectedAcode = $aFFCtrl.applyForFinancingParameters.acode;
                    $aFFCtrl.basePriceAndOptions = $aFFCtrl.applyForFinancingParameters.basePriceAndOptions;
                    $aFFCtrl.pricing = $aFFCtrl.applyForFinancingParameters.pricing;
                    $aFFCtrl.packageAndOptionsCode = $aFFCtrl.applyForFinancingParameters.packageAndOptionsCode;
                    $aFFCtrl.frequency = $aFFCtrl.applyForFinancingParameters.financeFrequency;
                    $aFFCtrl.financeTerm = $aFFCtrl.applyForFinancingParameters.financeTerm;
                    $aFFCtrl.financePayment = $aFFCtrl.applyForFinancingParameters.financePayment;
                    $aFFCtrl.provinceCode = $aFFCtrl.applyForFinancingParameters.provinceCode;
                    $aFFCtrl.jellyUrl = $aFFCtrl.applyForFinancingParameters.jellyUrl;

                    if ($aFFCtrl.applyForFinancingParameters.scratchSave) {
                        $aFFCtrl.scratchSave = $aFFCtrl.applyForFinancingParameters.scratchSave;
                        $aFFCtrl.otherFees = $aFFCtrl.applyForFinancingParameters.otherFees;
                        $aFFCtrl.incentivesTotal = $aFFCtrl.applyForFinancingParameters.incentivesTotal;
                        $aFFCtrl.financeDiscountTotalBeforeTax = $aFFCtrl.applyForFinancingParameters.financeDiscountTotalBeforeTax;
                        $aFFCtrl.financeDiscountTotalAfterTax = $aFFCtrl.applyForFinancingParameters.financeDiscountTotalAfterTax;
                    } else if ($aFFCtrl.applyForFinancingParameters.vin) {
                        $aFFCtrl.otherFees = 0;
                        $aFFCtrl.incentivesTotal = 0;
                        $aFFCtrl.basePriceAndOptions = $aFFCtrl.applyForFinancingParameters.dealerPrice
                        > 0 ?
                            $aFFCtrl.applyForFinancingParameters.dealerPrice :
                            $aFFCtrl.applyForFinancingParameters.basePriceAndOptions;
                    }
                    $scope.$$postDigest(function () {
                        $aFFCtrl.downpayment = $aFFCtrl.applyForFinancingParameters.downpayment;
                        $aFFCtrl.tradeInValue = $aFFCtrl.applyForFinancingParameters.tradeValue;
                        $aFFCtrl.oweOnTrade = $aFFCtrl.applyForFinancingParameters.oweOnTrade;

                        if ($aFFCtrl.scratchSave !== '') {
                            $rootScope.$on('initiateVehicleSelectorEvent',
                                (event, data) => {
                                    $rootScope.$broadcast(
                                        'lead-form:build-and-price-vehicle-info',
                                        {
                                            brandCode: $aFFCtrl.brandCode,
                                            nameplate: $aFFCtrl.selectedNameplate,
                                            trimName: $aFFCtrl.selectedTrim,
                                            year: $aFFCtrl.selectedYear,
                                            modelYearId: $aFFCtrl.selectedModelYearId,
                                            jellyUrl: $aFFCtrl.jellyUrl
                                        });
                                })
                        } else if ($aFFCtrl.vin !== '') {
                            $rootScope.$on('initiateDealerSelectorEvent',
                                (event, data) => {
                                    $rootScope.$broadcast(
                                        'lead-form:sni-vehicle-dealer-code', {
                                            dealer: $aFFCtrl.sniVehicle.dealerCode.toUpperCase()
                                        });
                                })
                        }

                        $scope.$watch('$aFFCtrl.basePriceAndOptions',
                            function () {
                                if ($aFFCtrl.basePriceAndOptions > 0) {
                                    $rootScope.$broadcast(
                                        'apply-for-financing-calculator:reset-calculator');
                                }
                            });

                        $rootScope.$on('calculator:finance-rate-changed',
                            (event, data) => {
                                $aFFCtrl.financeRate = data.financeInterestRate;
                                $aFFCtrl.monthlyEstimate = data.monthlyEstimate;
                            });

                        if (!angular.equals(
                            $aFFCtrl.applyForFinancingParameters, {})) {
                            sessionStorage.removeItem(
                                'applyForFinancingParameters');
                        }
                    });
                }
                $rootScope.$on('form-dealer-selector:single-dealer-selected',
                    (event, data) => {
                        $aFFCtrl.selectedDealer = data.dealer.toUpperCase();
                        $aFFCtrl.dealerTrackDealerId = data.dtdid;
                    });
                $rootScope.$on('form-vehicle-selector:nameplate-selected',
                    (event, data) => {
                        $aFFCtrl.selectedNameplate = data.nameplate;
                        $aFFCtrl.selectedYear = data.year;
                        $aFFCtrl.selectedNameplateName = data.nameplateName;
                        $aFFCtrl.selectedBrandName = data.brandName;
                        $aFFCtrl.brandCode = data.brandCode;
                    });
                $rootScope.$on('form-vehicle-selector:trim-selected',
                    (event, data) => {
                        $aFFCtrl.selectedTrim = data.trim;
                        $aFFCtrl.selectedModelYearId = data.modelYearId;
                        $aFFCtrl.selectedAcode = data.acode;
                        $aFFCtrl.basePriceAndOptions = data.basePrice;
                        $aFFCtrl.packageAndOptionsCode = data.packageAndOptionsCode;
                        $aFFCtrl.otherFees = data.otherFees;
                        $aFFCtrl.incentivesTotal = data.incentives;
                        $aFFCtrl.pricing = data.pricing;
                        $aFFCtrl.totalDiscounts = $aFFCtrl.sumIncentives($aFFCtrl.incentivesTotal);

                        console.debug('%cApply-for-credit-mode', 'color:LawnGreen; background:black',
                            {
                                monroney : $aFFCtrl.pricing.monroney.msrp,
                                incentives : $aFFCtrl.incentivesTotal,
                                totalDiscount : $aFFCtrl.totalDiscounts,
                                netAmount : "monroney - totalDiscounts = " + ($aFFCtrl.pricing.monroney.msrp - $aFFCtrl.totalDiscounts)
                            }
                        );

                        $scope.$$postDigest(function () {
                            $rootScope.$broadcast(
                                'apply-for-financing-calculator:reset-calculator');
                        });
                    });
                $rootScope.$on('form-vehicle-selector:jelly-info-updated',
                    (event, data) => {
                        $aFFCtrl.selectedMfgCode = data.mfgCode;
                        $aFFCtrl.jellyUrl = data.jellyUrl;
                    });
                $rootScope.$on(
                    'form-vehicle-selector:nameplate-name-and-brand-name',
                    (event, data) => {
                        $aFFCtrl.selectedNameplateName = data.nameplateName;
                        $aFFCtrl.selectedBrandName = data.brandName;
                    });
                if (window.pca) {
                    $aFFCtrl.initPca();
                }
                matchmedia.on(FCA_MQ_LAYOUT.MOBILE, (mediaQueryList) => {
                    if (mediaQueryList.matches) {
                        $aFFCtrl.applyForFinancing.sourceCode = 'MB';
                    } else {
                        $aFFCtrl.applyForFinancing.sourceCode = 'WS';
                    }
                });

                $scope.$$postDigest(function () {
                    if ($aFFCtrl.vin === '' && $aFFCtrl.scratchSave === '') {
                        $rootScope.$broadcast(
                            'lead-form:standalone-vehicle-info', null);
                    }
                });
            };

            $aFFCtrl.initPca = (options = {}) => {
                const pcaFields = [
                    {element: 'addressOne', field: 'Line1'},
                    {
                        element: 'addressTwo',
                        field: 'Line2',
                        mode: pca.fieldMode.POPULATE
                    },
                    {
                        element: 'city',
                        field: 'City',
                        mode: pca.fieldMode.POPULATE
                    },
                    {
                        element: 'province',
                        field: 'ProvinceName',
                        mode: pca.fieldMode.POPULATE
                    },
                    {
                        element: 'postalCode',
                        field: 'PostalCode',
                        mode: pca.fieldMode.POPULATE
                    }
                ];

                const pcaOptions = angular.extend({},
                    window.FCA_SITES_CONFIG.pca.options, options);

                $aFFCtrl.pcaControl = new pca.Address(pcaFields, pcaOptions);
                $aFFCtrl.pcaControl.listen('populate', $aFFCtrl.onPcaPopulate);

                $aFFCtrl.pcaControl.listen('ready', function () {
                    $aFFCtrl.pcaControl.enable();
                });
            };

            $aFFCtrl.onPcaPopulate = (address) => {
                $aFFCtrl.applyForFinancing.addressOne = address.Line1;
                $aFFCtrl.applyForFinancing.addressTwo = address.Line2;
                $aFFCtrl.applyForFinancing.city = address.City;
                $aFFCtrl.applyForFinancing.province = address.ProvinceCode;
                $aFFCtrl.applyForFinancing.postalCode = address.PostalCode;
            };

            $aFFCtrl.applyHashParameters = () => {
                let hash = $location.hash();
                if (hash) {
                    $aFFCtrl.hashParameters = getHashParameters();

                    // Sni info hash
                    if ($aFFCtrl.hashParameters.vin) {
                        // remove the session storage, anything we have in session storage we don't want anymore
                        sessionStorage.removeItem(
                            'applyForFinancingParameters');

                        $aFFCtrl.vin = $aFFCtrl.hashParameters.vin.toUpperCase();
                        $aFFCtrl.fetchSniDetails();
                    }
                    // Dealer code in Hash
                    if ($aFFCtrl.hashParameters.dealer) {
                        $aFFCtrl.dealer = $aFFCtrl.hashParameters.dealer.toUpperCase();
                        $rootScope.$on('initiateDealerSelectorEvent',
                            (event, data) => {
                                $rootScope.$broadcast(
                                    'lead-form:sni-vehicle-dealer-code',
                                    {dealer: $aFFCtrl.dealer});
                            });
                    }

                    // DAA vehicle in hash
                    if ($aFFCtrl.hashParameters.nameplate) {
                        $aFFCtrl.selectedNameplate = $aFFCtrl.hashParameters.nameplate;
                    }
                    if ($aFFCtrl.hashParameters.modelYearId) {
                        $aFFCtrl.selectedModelYearId = $aFFCtrl.hashParameters.modelYearId;
                    }
                    if ($aFFCtrl.hashParameters.acode) {
                        $aFFCtrl.selectedAcode = $aFFCtrl.hashParameters.acode;
                    }
                    if ($aFFCtrl.selectedNameplate !== ''
                        && $aFFCtrl.selectedModelYearId !== ''
                        && $aFFCtrl.selectedAcode !== '' && $aFFCtrl.scratchSave
                        === '') {
                        $rootScope.$on('initiateVehicleSelectorEvent',
                            (event, data) => {
                                $rootScope.$broadcast(
                                    'lead-form:daa-vehicle-info', {
                                        nameplate: $aFFCtrl.selectedNameplate,
                                        modelYearId: $aFFCtrl.selectedModelYearId,
                                        acode: $aFFCtrl.selectedAcode
                                    });
                            });
                    }
                }
            };

            $aFFCtrl.$postLink = () => {
                $aFFCtrl.writeUrlHash();

                if (window.addEventListener) {
                    window.addEventListener('message', $aFFCtrl.gotPDMessage);
                } else {
                    window.attachEvent("onmessage", $aFFCtrl.gotPDMessage);
                }
            };

            $aFFCtrl.gotPDMessage = (evt) => {
                /* convert message string to JSON */
                let page_code_active = window.BRANDS_GA.pagetype;
                let messageString = evt.data;
                let messageJSON;
                try {
                    messageJSON = JSON.parse(messageString);
                } catch (e) {
                    // We do nothing here, this method is meant to be used for data coming from Dealertrack, but catches more event than only those from DealerTrack
                }
                /* set iframe height based on height received in message */
                if (messageJSON) {
                    let DRSWidgetHeight = messageJSON.DRSWidgetHeight;
                    if (DRSWidgetHeight !== undefined && DRSWidgetHeight
                        !== null) {
                        $timeout(() => {
                            $aFFCtrl.iframeHeight = DRSWidgetHeight;
                            $aFFCtrl.loaded();
                        });
                    }
                    /* scroll page to top of iframe based on trigger received in message */
                    if (messageJSON.messageType == 'scroll') {
                        fcaScrollToElementService.scrollToElement($element, 0);
                    }
                    /* Get lead reference number, and set it into the local storage */
                    let leadReferenceNumber = messageJSON.LeadReferenceNumber;

                    if (leadReferenceNumber !== undefined && leadReferenceNumber
                        !== null) {
                        $aFFCtrl.submitConfirmation = true;

                        gtmAnalytics.trackEvent('event', {
                            category: 'App-Suite-' + page_code_active,
                            label: 'apply_credit_completed'
                        });
                    }
                }
            };

            $aFFCtrl.writeUrlHash = () => {
                const newHash = '#' + $location.hash();
                const newUrl = $aFFCtrl.languageLink + newHash;

                const $desktopHeaderLangLink = $('.main-nav-lang-toggle');
                const $mobileHeaderLangLink = $('.mobile-nav-lang-toggle a');
                const $footerSwitchLangLinks = $(
                    '.footer-province-switch-lang');

                if ($desktopHeaderLangLink.length > 0) {
                    $desktopHeaderLangLink.on('click', event => {
                        event.stopPropagation();
                        $desktopHeaderLangLink.attr('href', newUrl);
                    });
                }

                if ($mobileHeaderLangLink.length > 0) {
                    $mobileHeaderLangLink.on('click', event => {
                        event.stopPropagation();
                        $mobileHeaderLangLink.attr('href', newUrl);
                    });
                }

                if ($footerSwitchLangLinks.length > 0) {
                    $footerSwitchLangLinks.on('click', event => {
                        event.stopPropagation();
                        $footerSwitchLangLinks.attr('href', newUrl);
                    });
                }
            };

            $aFFCtrl.fetchSniDetails = () => {
                const vinUrl = `/data/inventories/${$aFFCtrl.brandCode}/vehicle/offer/${$aFFCtrl.vin}?language=${$aFFCtrl.language}`;
                $http.get(vinUrl).then((result) => {
                    $aFFCtrl.sniVehicle = result.data;
                    $aFFCtrl.selectedYear = $aFFCtrl.sniVehicle.offer.modelYear;
                    $aFFCtrl.selectedDealer = $aFFCtrl.sniVehicle.dealerCode;
                    if (angular.equals($aFFCtrl.applyForFinancingParameters,
                        {})) {
                        const calculatorData = JSON.parse(
                            $aFFCtrl.sniVehicle.calculatorDataJson);
                        $aFFCtrl.selectedAcode = calculatorData.acode;
                        if (calculatorData.dealerPrice > 0) {
                            $aFFCtrl.basePriceAndOptions = calculatorData.dealerPrice;
                            $aFFCtrl.otherFees = 0;

                            // Incentive are not applied when there is a dealer price
                            $aFFCtrl.incentivesTotal = 0;
                        } else {
                            // apply for financing doesn't need individual incentives
                            $aFFCtrl.basePriceAndOptions = $aFFCtrl.sniVehicle.incentivizedPrice;
                            $aFFCtrl.otherFees = 0;
                            // Incentive are not applied when there is a dealer price
                            $aFFCtrl.incentivesTotal = 0;
                        }

                        $aFFCtrl.packageAndOptionsCode = calculatorData.packageAndOptionsCode;
                        $scope.$$postDigest(function () {
                            if (angular.equals(
                                $aFFCtrl.applyForFinancingParameters, {})) {
                                $rootScope.$broadcast(
                                    'apply-for-financing-calculator:reset-calculator');
                            }
                        })
                    }

                    let jellies = [];
                    // This is sent to the Iframe (doesn't need to be responsive)
                    if ($aFFCtrl.sniVehicle.jellies
                        && $aFFCtrl.sniVehicle.jellies[0]
                        && $aFFCtrl.sniVehicle.jellies[0].jellies[0]) {
                        $aFFCtrl.jellyUrl = FCA_SITES_CONFIG.jelliesUrl.replace(
                            'jellies/renditions/', '')
                            + $aFFCtrl.sniVehicle.jellies[0].jellies[0].path;
                        jellies = $aFFCtrl.sniVehicle.jellies[0].jellies;

                    }

                    $aFFCtrl.selectedNameplate = $aFFCtrl.sniVehicle.offer.nameplatecode;
                    $aFFCtrl.selectedNameplateName = $aFFCtrl.sniVehicle.offer.nameplate[$aFFCtrl.language];
                    $aFFCtrl.selectedTrim = $aFFCtrl.sniVehicle.offer.trimGroup[$aFFCtrl.language];
                    $aFFCtrl.selectedModelYearId = $aFFCtrl.sniVehicle.offer.modelYearId;
                    $rootScope.$broadcast('lead-form:sni-vehicle-info', {
                        jellies: jellies,
                        nameplateCode: $aFFCtrl.sniVehicle.offer.nameplatecode,
                        trimGroupName: $aFFCtrl.sniVehicle.offer.trimGroup,
                        year: $aFFCtrl.selectedYear,
                        nameplateName: $aFFCtrl.sniVehicle.offer.nameplate,
                        vin: $aFFCtrl.vin
                    });
                    $rootScope.$broadcast('lead-form:sni-vehicle-dealer-code',
                        {dealer: $aFFCtrl.sniVehicle.dealerCode.toUpperCase()});
                });
            };

            $aFFCtrl.isNumericInput = (event) => {
                const key = event.keyCode;
                return ((key >= 48 && key <= 57) || // Allow Number line
                    (key >= 96 && key <= 105) // Allow NumPad
                );
            };

            $aFFCtrl.isModifierKey = (event) => {
                const key = event.keyCode;
                return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
                    (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
                    (key > 36 && key < 41) || // Allow left, up, right, down
                    (
                        // Allow Ctrl/Command + A,C,V,X,Z
                        (event.ctrlKey === true || event.metaKey === true) &&
                        (key === 65 || key === 67 || key === 86 || key === 88
                            || key === 90)
                    )
            };

            $aFFCtrl.enforceFormat = (event) => {
                if (!$aFFCtrl.isNumericInput(event) && !$aFFCtrl.isModifierKey(
                    event)) {
                    event.preventDefault();
                }
            };

            $aFFCtrl.formatPhoneNumber = (event) => {
                if ($aFFCtrl.isModifierKey(event)) {
                    return;
                }

                let target = event.target;
                const input = event.target.value.replace(/\D/g, '').substring(0,
                    10);
                const zip = input.substring(0, 3);
                const middle = input.substring(3, 6);
                const last = input.substring(6, 10);

                if (input.length > 6) {
                    target.value = `(${zip}) ${middle}-${last}`;
                } else if (input.length > 3) {
                    target.value = `(${zip}) ${middle}`;
                } else if (input.length > 0) {
                    target.value = `(${zip}`;
                }
            };

            $aFFCtrl.checkIfDealerAndVehicleSelected = () => {
                return $aFFCtrl.selectedDealer !== '' && $aFFCtrl.selectedTrim
                    !== '' && $aFFCtrl.financePayment > 0
                    && $aFFCtrl.vehicleChanged;
            };

            $rootScope.$on('form-vehicle-selector:vehicle-changed',
                (evt, args) => {
                    $aFFCtrl.vehicleChanged = args[0];
                    if (args[0]) {
                        $rootScope.$broadcast(
                            'calculator:switch-calculator-state', [true]);
                        $aFFCtrl.vehicleChosen = true;
                    } else {
                        $rootScope.$broadcast(
                            'calculator:switch-calculator-state', [false]);
                        $aFFCtrl.vehicleChosen = false;
                    }
                });

            $aFFCtrl.validateForm = () => {
                let $form;
                $('#aFFFormErrors').html('');
                $('.C_AFF-formGroup-input').removeClass('aff-form-error');

                function showErrorMsgs() {

                    const formErrorsArray = $('.aff-form-error');

                    if (formErrorsArray.length > 0 || $aFFCtrl.formErrors.length > 0) {
                        if ($aFFCtrl.language === 'fr') {
                            if (formErrorsArray.length === 1 || $aFFCtrl.formErrors.length === 1) {
                                $('#aFFFormErrors').append(
                                    `<p>Il y a ${formErrorsArray.length === 0 ? $aFFCtrl.formErrors.length : formErrorsArray.length } erreur dans le formulaire présentement. Veuillez la corriger afin de continuer.</p>`);
                            } else {
                                $('#aFFFormErrors').append(
                                    `<p>Il y a ${formErrorsArray.length === 0 ? $aFFCtrl.formErrors.length : formErrorsArray.length } erreurs dans le formulaire présentement. Veuillez les corriger afin de continuer.</p>`);
                            }
                        } else {
                            if (formErrorsArray.length === 1 || $aFFCtrl.formErrors.length === 1) {
                                $('#aFFFormErrors').append(
                                    `<p>There is ${formErrorsArray.length === 0 ? $aFFCtrl.formErrors.length : formErrorsArray.length } error in your form. Please correct it before submitting.</p>`)
                            } else {
                                $('#aFFFormErrors').append(
                                    `<p>There are ${formErrorsArray.length === 0 ? $aFFCtrl.formErrors.length : formErrorsArray.length } errors in your form. Please correct them before submitting.</p>`)
                            }
                        }
                        $aFFCtrl.errorMsgVisible = true;
                    } else {
                        $aFFCtrl.errorMsgVisible = false;
                    }
                    $timeout(function () {
                        $scope.$apply();
                        $('#aFFFormErrors').focus();
                    }, 0);
                }

                if ($aFFCtrl.showIdentificationStep) {
                    $form = $('#identificationStepSection');
                    $form.find('input').each(function () {
                        const index = $aFFCtrl.formErrors.indexOf(
                            $(this).attr('name'));
                        if ($(this).prop('required')) {
                            if ($(this).val() === '') {
                                $(this).addClass(
                                    'ng-invalid ng-touched aff-form-error');
                                $(this).next('div').removeClass('ng-hide');
                                if (index === -1) {
                                    $aFFCtrl.formErrors.push(
                                        $(this).attr('name'));
                                }
                            } else {
                                $(this).removeClass(
                                    'ng-invalid ng-touched aff-form-error');
                                $(this).next('div').addClass('ng-hide');
                                if (index !== -1) {
                                    $aFFCtrl.formErrors.splice(index, 1);
                                }
                            }
                        }
                    });

                    $form.find('select').each(function () {
                        const index = $aFFCtrl.formErrors.indexOf(
                            $(this).attr('name'));
                        if ($(this).prop('required')) {
                            if ($(this).children("option:selected").text()
                                === '') {
                                $(this).addClass(
                                    'ng-invalid ng-touched aff-form-error');
                                $(this).parent().next('div').removeClass(
                                    'ng-hide');
                                if (index === -1) {
                                    $aFFCtrl.formErrors.push(
                                        $(this).attr('name'));
                                }
                            } else {
                                $(this).removeClass(
                                    'ng-invalid ng-touched aff-form-error');
                                $(this).parent().next('div').addClass(
                                    'ng-hide');
                                if (index !== -1) {
                                    $aFFCtrl.formErrors.splice(index, 1);
                                }
                            }
                        }
                    });

                    // generate error message at top of form

                    showErrorMsgs();

                    if (!$aFFCtrl.formErrors.length) {
                        $aFFCtrl.showVehicleSelectorAndFinanceStep = true;
                        $aFFCtrl.showIdentificationStep = false;
                        $aFFCtrl.showIframe = false;
                        $rootScope.$broadcast(
                            'applyForFinancing:identification-step-sent', {
                                sent: true,
                                postalCode: $aFFCtrl.applyForFinancing.postalCode
                            });
                    }
                } else if ($aFFCtrl.showVehicleSelectorAndFinanceStep) {
                    let index;

                    if(!$aFFCtrl.vehicleChosen) {
                        if($aFFCtrl.formErrors.indexOf('jelly') === -1) {
                            $aFFCtrl.formErrors.push('jelly');
                        }
                        $('.C_AFF-errors-jelly').removeClass('invisible');
                    } else {
                        index = $aFFCtrl.formErrors.indexOf('jelly');
                        if (index !== -1) {
                            $aFFCtrl.formErrors.splice(index, 1);
                        }
                    }

                    $aFFCtrl.manageJellyError();

                    if (!$aFFCtrl.selectedDealer) {
                        if($aFFCtrl.formErrors.indexOf('dealer') === -1) {
                            $aFFCtrl.formErrors.push('dealer');
                        }
                        $('.C_FDS-form-dealerLocator-dealer-card').addClass(
                            'ng-invalid ng-touched');
                        $('.C_FDS-errors-dealerIds').removeClass('-invisible');
                    } else {
                        index = $aFFCtrl.formErrors.indexOf('dealer');
                        if (index !== -1) {
                            $aFFCtrl.formErrors.splice(index, 1);
                        }
                        $('.C_FDS-form-dealerLocator-dealer-card').removeClass(
                            'ng-invalid ng-touched');
                        $('.C_FDS-errors-dealerIds').addClass('-invisible');
                    }
                    showErrorMsgs();
                }
            };
    
            $aFFCtrl.getSecondStepErrorsContainerId = () => {
                if ($aFFCtrl.showVehicleSelectorAndFinanceStep) {
                    return 'aFFFormErrors';
                } else {
                    return 'aFFFormErrors-hidden';
                }
            };
            
            $aFFCtrl.submitApplication = () => {
                $("#confirmAndContinue").addClass('btn-spinner');

                const selectedNameplateName = $aFFCtrl.selectedNameplateName.replace(
                    /(<([^>]+)>)/ig, '');

                let prod;
                let term;
                let residualValue;
                let leaseMileage;
                let tradein;

                let msrp = Number($aFFCtrl.pricing.base.msrp) + Number($aFFCtrl.pricing.freight.msrp || 0);
                let salePrice = msrp + Number($aFFCtrl.pricing.options.msrp || 0);

                if ($aFFCtrl.financeMode === 'finance') {
                    prod = "LOAN";
                    term = $aFFCtrl.financeTerm;
                } else if ($aFFCtrl.financeMode === 'lease') {
                    prod = "LEASE";
                    term = $aFFCtrl.leaseTerm;
                    residualValue = $aFFCtrl.leaseResidual;
                    leaseMileage = $aFFCtrl.leaseMileage;
                }

                if ($aFFCtrl.tradeValue) {
                    tradein = $aFFCtrl.tradeValue - ($aFFCtrl.tradeOwed || 0);
                }

                var postData = {
                    "vin": $aFFCtrl.vin || '',
                    "title": $aFFCtrl.applyForFinancing.title,
                    "firstname": $aFFCtrl.applyForFinancing.firstName,
                    "lastname": $aFFCtrl.applyForFinancing.lastName,
                    "phone": $aFFCtrl.applyForFinancing.phone.replace(/[() \-]/g, ''),
                    "email": $aFFCtrl.applyForFinancing.email,
                    "confirmationemail": $aFFCtrl.applyForFinancing.email,
                    "city": $aFFCtrl.applyForFinancing.city,
                    "province": $aFFCtrl.applyForFinancing.province,
                    "zip": $aFFCtrl.applyForFinancing.postalCode,
                    "cond": "N",
                    "cpo": "N",
                    "modelnum": "",
                    "yr": $aFFCtrl.selectedYear,
                    "brandCode": $aFFCtrl.brandCode,
                    "model": selectedNameplateName,
                    "trim": $aFFCtrl.selectedTrim,
                    "msrp": msrp,
                    "prod": prod,
                    "term": term,
                    "lang": $aFFCtrl.language,
                    "cashdown": $aFFCtrl.downPaymentValue,
                    "tradein": tradein,
                    "excltax": "Y",
                    "yearlykms": leaseMileage,
                    "pdid": $aFFCtrl.dealerTrackDealerId,
                    "cp": salePrice,
                    "residualval": residualValue
                };

                $http({
                    url: "/api/financedriver/getUrl",
                    method: "POST",
                    data: JSON.stringify(postData),
                    contentType: "application/json"
                })
                .then(response => {
                    $aFFCtrl.iframeUrl = $sce.trustAsResourceUrl(response.data);
                }).catch(function (errorResponse) {
                });

                $aFFCtrl.showIframe = true;
                $aFFCtrl.checkIfIframeIsLoading();
                $aFFCtrl.showIdentificationStep = false;
                $aFFCtrl.showVehicleSelectorAndFinanceStep = false;
            };

            $aFFCtrl.loaded = () => {
                $('.C_AFF-loader-container').addClass('invisible');
            };

            $aFFCtrl.loading = () => {
                $('.C_AFF-loader-container').removeClass('invisible');
            };

            $aFFCtrl.checkIfIframeIsLoading = () => {
                $('.C_AFF-iframe').on('load', $aFFCtrl.loaded);
                $aFFCtrl.loading();
            };

            $aFFCtrl.setFormData = (form, data) => {
                for (let key in data) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                        $aFFCtrl.setFormValue(form, key, data[key]);
                    }
                }
            };

            $aFFCtrl.setFormValue = (form, key, value) => {
                let attribute = form[key];
                if (!attribute) {
                    attribute = document.createElement('input');
                    attribute.type = 'hidden';
                    attribute.name = key;
                    form.appendChild(attribute);
                }
                attribute.value = value;
            };

            $aFFCtrl.submitLead = () => {
                const leadObject = {
                    postType: 'contact_a_dealer',
                    leadType: 'credit',
                    title: $aFFCtrl.applyForFinancing.title.replace('.', ''),
                    firstname: $aFFCtrl.applyForFinancing.firstName,
                    lastname: $aFFCtrl.applyForFinancing.lastName,
                    email: $aFFCtrl.applyForFinancing.email,
                    phone: $aFFCtrl.applyForFinancing.phone,
                    address: $aFFCtrl.applyForFinancing.addressOne,
                    address2: $aFFCtrl.applyForFinancing.addressTwo,
                    nameplateCode: $aFFCtrl.selectedNameplate,
                    year: $aFFCtrl.selectedYear,
                    trimDescription: $aFFCtrl.selectedTrim,
                    postalCode: $aFFCtrl.applyForFinancing.postalCode,
                    acode: $aFFCtrl.selectedAcode,
                    vin: $aFFCtrl.vin,
                    leadComments: $aFFCtrl.buildPaymentsInfo(),
                    scratchSave: $aFFCtrl.scratchSave,
                    dealerIds: $aFFCtrl.selectedDealer
                };
                $.post(
                    `/api/apply-for-financing/${$aFFCtrl.language}/brand/${$aFFCtrl.brandCode}`,
                    leadObject, function (data) {
                        if (!data.success) {
                            $aFFCtrl.formErrors = data.fieldErrors;
                        } else {
                            $("#confirmAndContinue").removeClass('btn-spinner');
                        }
                    });
            };

            $aFFCtrl.buildPaymentsInfo = () => {

                let result = "Payment mode : " + $aFFCtrl.financeMode;

                result += `\nMSRP: $${$aFFCtrl.pricing.base.msrp}\n`;

                if ($aFFCtrl.financeMode === 'finance') {
                    result += `\tTerm: ${$aFFCtrl.financeTerm} months\n`
                        + `\tPayment: $${$aFFCtrl.financePayment}\n`
                        + `\tRate: ${$aFFCtrl.financeRate}%\n`
                        + `\tFrequency: ${$aFFCtrl.frequency}\n`;
                } else if ($aFFCtrl.financeMode === 'lease') {
                    result += `\tTerm: ${$aFFCtrl.leaseTerm} months\n`
                        + `\tPayment: $${$aFFCtrl.leasePayment}\n`
                        + `\tRate: ${$aFFCtrl.leaseRate}%\n`
                        + `\tFrequency: ${$aFFCtrl.frequency}\n`
                        + `\tResidual value: $${$aFFCtrl.leaseResidual}\n`
                        + `\tMileage: ${$aFFCtrl.leaseMileage} km\n`;
                }

                if ($aFFCtrl.tradeValue) {
                    let tradein = $aFFCtrl.tradeValue - ($aFFCtrl.tradeOwed || 0);
                    result += `\tTrade-In value (Trade-In - Trade Owed) : $${tradein}\n`;
                }

                if ($aFFCtrl.downPaymentValue) {
                    result += `\tDown Payment : $${$aFFCtrl.downPaymentValue}\n`;
                }

                return result;
            };

            $aFFCtrl.submitConfirmAndContinue = () => {
                $aFFCtrl.validateForm();
                if (!$aFFCtrl.formErrors.length) {
                    $aFFCtrl.submitApplication();
                    $aFFCtrl.submitLead();
                    $aFFCtrl.getVlpUrl();
                }
            };

            $aFFCtrl.getVlpUrl = () => {
                const apiUrl = (
                    `/api/pageurl/vlp/${$aFFCtrl.brandCode}/${$aFFCtrl.selectedNameplate}/${$aFFCtrl.selectedYear}`
                );

                $http.get(apiUrl)
                .then((response) => {
                    if ($aFFCtrl.language == 'fr') {
                        $aFFCtrl.vlpUrl = response.data.fr;
                    } else {
                        $aFFCtrl.vlpUrl = response.data.en;
                    }
                })
                .catch(vlpUrlError => {
                    console.log("Error on VLP url : " + vlpUrlError);
                });
            };

            $aFFCtrl.showStep = (argStep) => {
                if (argStep === 'identification') {
                    $aFFCtrl.showVehicleSelectorAndFinanceStep = false;
                    $aFFCtrl.showIdentificationStep = true;
                } else if (argStep === 'selectors') {
                    $aFFCtrl.showVehicleSelectorAndFinanceStep = true;
                    $aFFCtrl.showIdentificationStep = false;
                }
            };

            $aFFCtrl.animateScrollOnBtnClick = () => {
                $("html, body").animate(
                    {scrollTop: $('.C_AFF-form').offset().top}, 300);
            }

            $aFFCtrl.manageJellyError = () => {
                const selectInput = $('.C_FVS-form-formGroup-input');
                
                selectInput.focus(() => {
                    $('.C_AFF-errors-jelly').addClass('invisible');
                })
            }

            $aFFCtrl.sumIncentives = (incentives) => {
                let sumIncentives = 0;
                if(incentives) {
                    incentives.forEach(incentive => {
                        sumIncentives += parseInt(incentive.cash);
                    });
                }
                return sumIncentives;
            }
        }
    }
})();
