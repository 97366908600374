(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator')
		.controller('fcaGeolocationDialogController', FcaGeolocationDialogController);

	/**
	 * @ngdoc controller
	 * @name fca.fcaGeolocator.controller:fcaGeolocationDialogController
	 * @description [TODO]
	 * @requires ngDialog
	 * @example
	 * <pre>
	 * [TODO]
	 * </pre>
	 */
	function FcaGeolocationDialogController($scope, $rootScope, ngDialog) {
		'ngInject';
		/**
		 * @ngdoc property
		 * @name dialogTitle
		 * @description Dialog modal title
		 * @type {String}
		 */
		// this.dialogTitle = $filter('translate')($scope.ngDialogData.title);
		this.dialogTitle = $scope.ngDialogData.title;

		/**
		 * @ngdoc property
		 * @name viewAllUrl
		 * @description link view All url
		 * @type {String}
		 */
		this.viewAllUrl = $scope.ngDialogData.viewAllUrl || false;

		/**
		 * @ngdoc method
		 * @name close
		 * @methodOf fca.fcaGeolocator.controller:fcaGeolocationDialogController
		 * @description [TODO]
		 * @param  {Object} $evt [TODO]
		 * @example
		 * <pre>
		 * [TODO]
		 * </pre>
		 */
		this.close = ($evt) => {
			$rootScope.$broadcast('reset-scroll-on-close-dialog');
			$evt.preventDefault();
			/* Close modal without location setting */
			ngDialog.close(null, null);
		};

		/**
		 * @ngdoc method
		 * @name locationChange
		 * @methodOf fca.fcaGeolocator.controller:fcaGeolocationDialogController
		 * @description [TODO]
		 * @param  {Object} location [TODO]
		 * @example
		 * <pre>
		 * [TODO]
		 * </pre>
		 */
		this.locationChange = (location) => {
			/* Close modal with new location settings */
			ngDialog.close(null, location);
			return;
		};
	}
})(angular);
