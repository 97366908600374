(function(ng) {
	'use strict';

	angular
		.module('fca.geolocator')
		.service('defaultProvinceService', DefaultProvinceService);

	function DefaultProvinceService(userLocation, cookieLocation) {
		'ngInject';

		const REGION_PROVINCE_MAP = {
			ON: {
				region: 'ontario',
				default: false,
				defaultCity: {
					name: 'Toronto',
					province: 'ON',
					region: 'ontario',
					postalCode: 'M4W 1A1',
					latitude: '43.653226',
					longitude: '-79.383184299'
				}
			},
			QC: {
				region: 'quebec',
				default: false,
				defaultCity: {
					name: 'Montreal',
					province: 'QC',
					region: 'quebec',
					postalCode: 'H1X 2M7',
					latitude: '45.5016889',
					longitude: '-73.5672559'
				}
			},
			BC: {
				region: 'pacific',
				default: false,
				defaultCity: {
					name: 'Vancouver',
					province: 'BC',
					region: 'pacific',
					postalCode: 'V5N 1B7',
					latitude: '49.2827291',
					longitude: '-123.12073750000002'
				}
			},
			AB: {
				region: 'alberta',
				default: true,
				defaultCity: {
					name: 'Calgary',
					province: 'AB',
					region: 'alberta',
					postalCode: 'T2N 2X8',
					latitude: '51.0486151',
					longitude: '-114.0708459'
				}
			},
			MB: {
				region: 'prairies',
				default: true,
				defaultCity: {
					name: 'Winnipeg',
					province: 'MB',
					region: 'prairies',
					postalCode: 'R3C 1S4',
					latitude: '49.8963007',
					longitude: '-97.2190729'
				}
			},
			NB: {
				region: 'atlantic',
				default: true,
				defaultCity: {
					name: 'Fredericton',
					province: 'NB',
					region: 'atlantic',
					postalCode: 'E3B 9L8',
					latitude: '45.9640029',
					longitude: '-66.6519356'
				}
			},
			NL: {
				region: 'atlantic',
				default: true,
				defaultCity: {
					name: 'St.John\'s',
					province: 'NL',
					region: 'atlantic',
					postalCode: 'A1C 1G9',
					latitude: '47.565616',
					longitude: '-52.7076251'
				}
			},
			NT: {
				region: 'pacific',
				default: true,
				defaultCity: {
					name: 'Yellowknife',
					province: 'NT',
					region: 'pacific',
					postalCode: 'X1A 1S6',
					latitude: '62.4532979',
					longitude: '-114.3753856'
				}
			},
			NS: {
				region: 'atlantic',
				default: true,
				defaultCity: {
					name: 'Halifax',
					province: 'NS',
					region: 'atlantic',
					postalCode: 'B3J 2V9',
					latitude: '44.6483239',
					longitude: '-63.5789755'
				}
			},
			NU: {
				region: 'alberta',
				default: true,
				defaultCity: {
					name: 'Iqaluit',
					province: 'NU',
					region: 'alberta',
					postalCode: 'X0A 0H0',
					latitude: '63.7495741',
					longitude: '-68.5206178'
				}
			},
			PE: {
				region: 'atlantic',
				default: true,
				defaultCity: {
					name: 'Charlottetown',
					province: 'PE',
					region: 'atlantic',
					postalCode: 'C1A 4L6',
					latitude: '46.2384061',
					longitude: '-63.1322342'
				}
			},
			SK: {
				region: 'prairies',
				default: true,
				defaultCity: {
					name: 'Regina',
					province: 'SK',
					region: 'prairies',
					postalCode: 'S4P 0S4',
					latitude: '50.4447547',
					longitude: '-104.6213556'
				}
			},
			YT: {
				region: 'pacific',
				default: true,
				defaultCity: {
					name: 'Whitehorse',
					province: 'YT',
					region: 'pacific',
					postalCode: 'Y1A 6E4',
					latitude: '60.7211871',
					longitude: '-135.0568449'
				}
			},
			DE: {
				region: 'default',
				default: true
			}
		};

		const DEFAULT_REGION_PROVINCE_MAP = {
			ontario: REGION_PROVINCE_MAP.ON.defaultCity,
			quebec: REGION_PROVINCE_MAP.QC.defaultCity,
			pacific: REGION_PROVINCE_MAP.BC.defaultCity,
			alberta: REGION_PROVINCE_MAP.AB.defaultCity,
			prairies: REGION_PROVINCE_MAP.MB.defaultCity,
			atlantic: REGION_PROVINCE_MAP.NS.defaultCity
		};

		const $service = this;

    $service.getDefaultProvince = (region) => {
			return DEFAULT_REGION_PROVINCE_MAP[region];
		};

    $service.getProvince = (province) => {
			return REGION_PROVINCE_MAP[province];
		};

    $service.updateBasedOnRegion = (region) => {
      if (userLocation.getLocation() !== null) {
        $service.setCookieValues((region === userLocation.getLocation().region) ?
          userLocation.getLocation() :
          $service.getDefaultProvince(region));
      }
    };

    $service.updateBasedOnProvince = (province) => {
      if (userLocation.getLocation() !== null) {
        $service.setCookieValues((province === userLocation.getLocation().province) ?
          userLocation.getLocation() :
          $service.getProvince(province).defaultCity);
      }
    };

    $service.setCookieValues = (province) => {
      let cookie = cookieLocation.getLocation();
      cookie.name = province.name;
      cookie.locationCityLabel = province.name;
      cookie.province = province.province;
      cookie.region = province.region;
      cookie.postalCode = province.postalCode;
      cookie.latitude = province.latitude;
      cookie.longitude = province.longitude;
      cookieLocation.setLocation(cookie);
    };
	}
})(angular);
